import { staticport } from './../../global';
import { contactuspojo } from './../pojo/contactusPojo';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, observable } from 'rxjs';
import { uploadcvpojo } from '../pojo/upload-cvpojo';

@Injectable({
  providedIn: 'root'
})
export class ContactUsServiceService {

  constructor( private HttpClient: HttpClient) { }

  // savecontactdetails(arg:contactuspojo):Observable<contactuspojo>{
  //  const url = staticport.porturl + 'AddContactUsPage';
  //  return this.HttpClient.post<contactuspojo>(url, arg);
  // }
  getcontactdetails(arg:contactuspojo):Observable<contactuspojo[]>{
    const url = staticport.porturl + 'GetContactUs';
    return this.HttpClient.get<contactuspojo[]>(url);
  }
  updatecontactdetails(arg:contactuspojo):Observable<contactuspojo>{
    const url = staticport.porturl + 'UpdateContactUs';
    return this.HttpClient.put<contactuspojo>(url, arg);
  }
  deletecontactdetails(arg:number):Observable<contactuspojo>{
    const url = staticport.porturl + 'DeleteContactUs';
    return this.HttpClient.delete<contactuspojo>(url)
  }
  contactUsDetails(arg: uploadcvpojo): Observable<uploadcvpojo> {
    const url = staticport.porturl +'AddContactUsPage';
    return this.HttpClient.post<uploadcvpojo>(url, arg);
  }
}
