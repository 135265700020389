<div class="container my-5">
  <div class="title-box bg-white">
    <div class="p-3 domestic-text">
      <h2 class="text-center text-black m-0 font-weight-bold">DOMESTIC RECRUITMENT</h2>
    </div>
    <!-- <div class="breadcrumb-section mt-3 text-center">
      <h5 class="text-muted mb-0">
        <span class="sub-menu font-weight-bold">
          <a routerLink="/home-page" class="text-primary text-decoration-none">Home</a>
        </span> &nbsp;/&nbsp;
        <span class="sub-menu font-weight-bold">
          <a routerLink="/mobile-development" class="text-primary text-decoration-none">Staffing</a>
        </span>
      </h5>
    </div> -->
  </div>
  
  

  <div class="image-section text-center mt-4">
    <img src="../../../assets/img/home/male-female-business-people-working-tablet-office.jpg" 
         alt="Company Image" 
         class="img-fluid rounded shadow" 
         style="max-width: 80%; height: auto;">
  </div>
</div>

<!-- Description Section -->
<div class="container mt-5">
  <p class="text-center font-weight-bold lead" style="text-align: justify;">
    We place candidates to fulfill the needs of our esteemed clients. We are fully committed to sourcing and engaging qualified and skilled candidates for organizations.
  </p>
  <p class="text-center font-weight-bold" style="text-align: justify;">
    Ensure compliance with labor laws and regulations in the recruitment process.
    Focus on building a diverse and inclusive workplace.
  </p>
  <div class="row mt-4">
    <!-- Left Column: Services -->
    <div class="col-md-4 button1">
        <div class="card shadow-sm p-4 border-left-primary">
            <h5 class="font-weight-bold text-center" style="color: #A35C7A;">Services We Provide</h5>
            <div class="text-muted mt-3">
                ✅ IT & ITES <br>
                ✅ Telecom <br>
                ✅ Manufacturing & Engineering <br>
                ✅ Banking & Finance <br>
                ✅ Hospitality & Aviation <br>
                ✅ Construction
            </div>
        </div>
    </div>

    <!-- Middle Column: Recruitment Process -->
    <div class="col-md-4 button2">
        <div class="card shadow-sm p-4 border-left-success">
            <h5 class="font-weight-bold text-center" style="color: #A35C7A;">Recruitment Process</h5>
            <div class="text-muted mt-3">
                🔹 Resource Sourcing <br>
                🔹 Scheduling Interviews <br>
                🔹 Primary Screening <br>
                🔹 Shortlisting Candidates <br>
                🔹 Final Selection <br>
                🔹 HR-Related Services
            </div>
        </div>
    </div>

    <!-- Right Column: Database Building -->
    <div class="col-md-4 button3">
        <div class="card shadow-sm p-4 border-left-info">
            <h5 class=" font-weight-bold text-center" style="color: #A35C7A;">Database Building</h5>
            <div class="text-muted mt-3">
                📌 References <br>
                📌 Job Fairs <br>
                📌 Print Advertisements <br>
                📌 Proactive Sourcing <br>
                📌 Online Job Portals <br>
                📌 Campus Visits
            </div>
        </div>
    </div>
</div>
</div>


<style>
  /* Main Container */
.content-container {
  display: flex;
  justify-content: space-between;
  gap: 20px; 
  flex-wrap: wrap;
  padding: 20px;
}
.image-section img{
  box-shadow: 4px 4px 30px #A35C7A;
}

.domestic-text h2::first-letter{
  color: red;
  font-size: 150%;
}
.left-column {
  width: 48%; /* Takes half the page for larger screens */
  text-align: left;
}
.button1{
  border: 1px solid #A35C7A;
  box-shadow: 4px 4px 30px #A35C7A;
}
.button2{
  border: 1px solid #A35C7A;
  box-shadow: 4px 4px 30px #A35C7A;
}
.button3{
  border: 1px solid #A35C7A;
  box-shadow: 4px 4px 30px #A35C7A;
}

.right-column {
  width: 48%; 
  text-align: left;
}


.right-aligned {
  color: #007bff;
  font-weight: bold;
  margin-bottom: 10px;
}


ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  padding-left: 1.5rem;
  position: relative;
}

/* Add bullets using ::before */
ul li::before {
  content: '●';
  position: absolute;
  left: 0;
  /* color: #007bff; */
  font-size: 1.2rem;
  top: 50%;
  transform: translateY(-50%);
}

/* Add spacing between the first and second sections in the right column */
.right-column p + ul {
  margin-top: 10px;
}

/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
  .left-column,
  .right-column {
    width: 100%; /* Stacks both columns */
    margin-bottom: 20px; /* Adds spacing between stacked sections */
  }
  
  .content-container {
    gap: 0; /* Removes gap for stacked layout */
  }
}
  /* Animation Styles */
.animatable {
  opacity: 0;
  transform: translateX(100px); /* Starts off to the right */
  transition: opacity 1s ease-out, transform 4s ease-out;
}

/* For elements that animate from the right */
.animate-from-right {
  animation: slideInFromRight 4s forwards;
}

/* For elements that animate from the left */
.animate-from-left {
  animation: slideInFromLeft 4s forwards;
}

/* Keyframes for sliding in from the right */
@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Keyframes for sliding in from the left */
@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Optional: Add a delay for the first element */
.animatable.animate-from-right {
  animation-delay: 0.2s;
}

.animatable.animate-from-left {
  animation-delay: 0.4s;
}

/* Other styling for text */
.description-text p {
  font-size: 18px; /* Adjust as per preference */
  color: #333;
  margin: 10px 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .description-text p {
    font-size: 16px; /* Adjust for smaller screens */
  }
}
  /* Global Styles */
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers horizontally */
    justify-content: center; /* Centers vertically */
    min-height: 100vh; /* Ensures full-page height */
    background-color: #f9f9f9; /* Optional background color */
  }

  p {
    text-align: center;
    font-size: 18px; /* Adjust as per preference */
    color: #333; /* Optional text color */
    margin: 10px 0;
  }

  .highlight-text {
    font-weight: bold;
    color: #007bff; 
  }

  /* Default Image Styling for all screen sizes */
.responsive-img {
  width: 100%;              
  height: auto;               
  object-fit: cover;        
  max-height: 500px;          
}

/* Mobile and tablet responsiveness */
@media (max-width: 768px) {
  .responsive-img {
    width: 100%;              
    height: auto;             
    object-fit: cover;        
    max-height: 300px;       
  }
}

/* For larger screen sizes like tablets and desktops */
@media (min-width: 769px) and (max-width: 1024px) {
  .responsive-img {
    width: 80%;              
    height: auto;             
    object-fit: cover;       
    max-height: 800px;       
  }
}

/* For desktop view */
@media (min-width: 1025px) {
  .responsive-img {
    width: 80%;              
    height: auto;            
    object-fit: cover;       
    max-height: 700px;        
  }
}


 /* Container to hold bullet points and headings */
.bullet-container {
  display: flex;
  flex-direction: column;  /* Stack items vertically */
  align-items: flex-end;   /* Align items to the right */
  margin-bottom: 30px;     /* Space between sections */
}

.bullet-column {
  display: flex;
  flex-direction: column;
  align-items: flex-end;   /* Align list items to the right */
}

.right-aligned {
  text-align: right;       /* Align text to the right */
  margin-bottom: 10px;     /* Space between heading and list */
}

/* Custom Style for List Items */
.bullet-column ul {
  list-style-type: none;
  padding-left: 0;
  text-align: right;       /* Align the list items to the right */
}

.bullet-column ul li {
  padding-left: 1.5rem;    /* Indentation for bullet points */
  position: relative;
}

.bullet-column ul li::before {
  content: '●';
  position: absolute;
  left: 0;
  /* color: #007bff; */
  font-size: 1.2rem;
  top: 50%;
  transform: translateY(-50%);
}

/* Optional: For animation classes */
.animatable {
  transition: all 0.5s ease-in-out;
}

.animate-from-left {
  animation: slideInLeft 1s forwards;
}

.animate-from-right {
  animation: slideInRight 1s forwards;
}

/* Keyframes for animation */
@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

</style>

 