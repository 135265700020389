
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { staticport } from 'src/global';


@Injectable({
  providedIn: 'root'
})
export class GalleryPageServiceService {

  constructor( private HttpClient: HttpClient) { }

  getGallery():Observable<any[]>{
       const url = staticport.porturl + 'image/GetGalleryAll';
       return this.HttpClient.get<any[]>(url);
     }
  getJobImage():Observable<any[]>{
       const url = staticport.porturl + 'image/GetJobImage';
       return this.HttpClient.get<any[]>(url);
     }
}