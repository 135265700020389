<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Contact Us</title>
</head>
<body>
  <div class="text-center mt-4 container2">
    <a class="mail-call"  href="tel:9894355288">
      <span class="fa fa-phone phone-custom" style="color: #C890A7;background: linear-gradient(#C890A7, #A35C7A); font-size: 20px; text-decoration: none ;padding: 3px; color: white;" aria-hidden="true"></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </a>  
    <a class="mail-call1" href="mailto:Info@sbconsultancy.org.in,mailto:sbconsultancytnj&#64;gmail.com">
      <span class="fa fa-envelope" style="font-size: 20px;  color: #C890A7;background: linear-gradient(#C890A7, #A35C7A); color: white; padding: 3px; "></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </a>   
    <a class="mail-call2" href="https://wa.me/9894355288" target="_blank">
      <i class="fa fa-whatsapp" style="color: #C890A7;background: linear-gradient(#C890A7, #A35C7A); color: white; font-size: 20px; padding: 3px;"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </a>
    <a class="mail-call3" href="https://www.instagram.com/sbc3600?utm_source=qr&igsh=eDRnM2xrbXo2aDUx" target="_blank">
      <i class="fa fa-instagram" style="color: #C890A7;background: linear-gradient(#C890A7, #A35C7A); color: white; font-size: 20px; padding: 3px;"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </a>
    <a class="mail-call4" href="https://www.facebook.com/profile.php?id=61572205600780" target="_blank">
      <i class="fa fa-facebook" style="color: #C890A7;background: linear-gradient(#C890A7, #A35C7A); color: white;; font-size: 20px; padding: 3px; "></i>&nbsp;&nbsp;
    </a>
  </div>

  <section class="contact-us">
    <!-- <h1>Contact Us</h1> -->
    <div class="blinking-text">
      
      <p style="font-size: 35px; font-weight: bold; color: #A35C7A; text-align: center;">
        <img src="../../assets/img/icon/image (1).png" height="70px" width="70px" alt="SB CONSULTANCY">&nbsp;SB CONSULTANCY
      </p>      
    
    </div>
  </section>

  <div class="row1">
    <div class="col-lg-6 text-center w3-animate-left" style="padding-right: 0px;">
        <div class="contact-detail">
            <img src="../../assets/img/contact/contact1.jpg" alt="Notebook" class="contact-img text-center">
            <div class="content p-4">
                <br /><br />
                <h1 class="text-light align-middle" style="color: white;">LOCATION</h1>
                <h4 class="align-middlefont-weight-bold">SB CONSULTANCY
                    <sup >
                        <i class="fa fa-registered" aria-hidden="true"></i>
                    </sup>
                    
                </h4>
                <p class="align-middle">
                  Akila Complex, Shop No:B11 & B12,
                    <br />
                    Odathurai Street Thiruvaiyaru,
                    <br />
                    Thanjavur - Dt,Pin - 613204, Tamil Nadu.
                </p>
            
                <h1 class="text-light">Phone | E-mail | Website</h1>
                <p>
                    <a class="mail-call" href="tel:9894355288">+91 98943 55288</a>
                    <br />
                    For General Enquiries: 
                    <a class="mail-call" href="mailto:Info@sbconsultancy.org.in">Info@sbconsultancy.org.in</a>
                    <br /><br>
                    <br />
                </p>
                
        </div>
    </div>
</div>
</div>
<section class="contact-form" style="height: 100vh; display: flex; justify-content: center; align-items: center;">
  <div class="form-container">
    <form action="#" method="POST" autocomplete="off" class="container mt-4" style="max-width: 600px; width: 100%; background-color: #fff; padding: 20px; border-radius: 8px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);">
      <h2 class="text-center mb-4"> <img src="../../assets/img/icon/image (1).png" height="70px" width="70px" alt="SB CONSULTANCY">&nbsp;Contact Form</h2>

      <!-- Row 1 -->
      <div class="row mb-3">
        <div class="col-sm-6">
          <label for="fullName" class="form-label">Full Name</label>
          <input
            type="text"
            id="fullName"
            class="form-control"
            [(ngModel)]="contactus.fullName"
            name="fullName"
            placeholder="Enter Name Here (required)"
            required
          />
        </div>
        <div class="col-sm-6">
          <label for="mailId" class="form-label">Email Address</label>
          <input
            type="email"
            id="mailId"
            class="form-control"
            [(ngModel)]="contactus.mailId"
            name="mailId"
            placeholder="Enter Email Address (required)"
            required
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-6">
          <label for="phoneNo" class="form-label">Phone Number</label>
          <input
            type="tel"
            id="phoneNo"
            class="form-control"
            [(ngModel)]="contactus.phoneNo"
            name="phoneNo"
            placeholder="Enter Phone Number (required)"
            required
          />
        </div>
        <div class="col-sm-6">
          <label for="message" class="form-label">Message</label>
          <textarea
            id="message"
            class="form-control"
            [(ngModel)]="contactus.message"
            name="message"
            placeholder="Enter your message here"
            rows="4"
            required
          ></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 text-end">
          <button type="submit" class="btn btn-primary" (click)="submit1()">Submit</button>
        </div>
      </div>
    </form>
  </div>
</section> 
</body>
</html>

    <div class="container-fluid animatable bounceIn">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15672.40647347826!2d79.09574122823587!3d10.879875200556151!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baac3fc29abc855%3A0xe3d0f49ba4f489ea!2sThiruvaiyaru%2C%20Tamil%20Nadu%20613204!5e0!3m2!1sen!2sin!4v1733306724119!5m2!1sen!2sin" width="100%" height="300" frameborder="0"  allowfullscreen="" >
        </iframe>
    </div>

 