<div class="container">
  <!-- Header Section with Gradient Background -->
  <!-- <div class="title-box uk-border-rounded p-5" style="background: linear-gradient(135deg, #007bff, #6c757d);">
    <div class="header-section text-white p-4 rounded-top text-center">

    </div>
    <div class="breadcrumb-section mt-4 text-right">
      <h5 class="text-muted">
        <span class="sub-menu" routerLink="/home-page">Home </span>&nbsp;/&nbsp;
        <span class="sub-menu sub-menu-text font-weight-bold">Custom Software Development</span>&nbsp;/&nbsp; 
        <span class="sub-menu" routerLink="/maintenance">Training</span>
      </h5>
    </div>
  </div> -->
  <br>
  <h1 class="title-text1 text-center font-weight-bold">PAYROLL MANAGEMENT</h1>
  <div class="hero-section text-center my-5" style="background: #f0f8ff; padding: 50px;">
    <h2 class="font-weight-bold mb-4">EFFICIENT PAYROLL PROCESSING FOR YOUR BUSINESS</h2>
    <p class="lead text-muted mb-4">Streamline your payroll and focus on what truly matters.</p>
    <button routerLink="/ourproducts" class="btn-lg" style="background: linear-gradient(180deg, #C890A7, #A35C7A); border: none; color: white;">Get Started</button>
  </div>

  <!-- Cards Section with Icons and Hover Effect -->
  <div class="row">
    <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
      <div class="card shadow-lg">
        <div class="card-body text-center">
          <i class="fa fa-cogs fa-3x mb-3"></i>
          <h5 class="card-title">Automated Payroll</h5>
          <p class="card-text">We provide an automated payroll system that reduces errors and saves time.</p>
          <a href="#" routerLink="/upload-cv" class="" style="color: #A35C7A;">Learn More</a>
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
      <div class="card shadow-lg">
        <div class="card-body text-center">
          <i class="fa fa-users fa-3x mb-3"></i>
          <h5 class="card-title">Employee Management</h5>
          <p class="card-text">Manage employee payroll with ease and keep track of all important data.</p>
          <a href="#" routerLink="/upload-cv" class="" style="color: #A35C7A;">Learn More</a>
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
      <div class="card shadow-lg">
        <div class="card-body text-center">
          <i class="fa fa-check fa-3x mb-3"></i>
          <h5 class="card-title">Compliance & Security</h5>
          <p class="card-text">Ensure compliance with regulations and keep your payroll secure.</p>
          <a href="#" routerLink="/upload-cv" class="" style="color: #A35C7A;">Learn More</a>
        </div>
      </div>
    </div>
  </div>


  <div class="description-text mx-3 my-5">
    <!-- Row for Text Columns -->
    <div class="row align-items-center">
      <!-- First Column - Left-aligned Text -->
      <div class="col-md-6 col-sm-12 mb-4 hash1">
        <p class="animatable animate-from-left text-left font-weight-bold" style="font-size: 20px;">
          Managing complete payroll processing in addition to your core business processes can become quite overwhelming and often result in payroll issues.
        </p>
      </div>
      
      <!-- Second Column - Right-aligned Text -->
      <div class="col-md-6 col-sm-12 mb-4 hash2" >
        <p class="animatable animate-from-right text-left font-weight-bold" style="font-size: 20px;">
          It is very essential for every organization to have efficient payroll processing in order to streamline payroll, optimize productivity,
           and improve administration. SB Consultants ensures smooth payroll management services that can help achieve better resource 
           management and save you the hassles and time-consuming tasks of payroll processing.
        </p>
      </div>
    </div>
  
    <!-- Full-width Centered Text -->
    <div class="text-center mt-4 hash3">
      <p class="animatable animate-from-left text-left font-weight-bold" style="font-size: 20px;">
        We assure you to provide the best services for payroll processing and permit you to focus your time and energy on your core business functions.
      </p>
    </div>
  </div>
  <br>
  <div style="flex: 1; height: 2px; background: #ddd; margin: 0 10px; position: relative;">
    <i class="fa fa-rocket" style="position: absolute; top: -12px; left: 50%; transform: translateX(-50%); background: #fff; padding: 5px; color: #A35C7A; border-radius: 50%;"></i>
  </div>
  <br>
  
</div>

<style>
  /* Main Container */
.content-container {
  display: flex;
  justify-content: space-between; 
  gap: 20px; 
  flex-wrap: wrap; 
  padding: 20px;
}
@keyframes blink{
  50%{
    opacity: 0;
  }
}
.row a {
  animation: blink 2s infinite;
}
.hero-section h2:hover{
  color: red;
}


.description-text .hash1 p::first-letter{
  color: red;
  font-size: 200%;
}
.hash2 p::first-letter{
  color: red;
  font-size: 200%;
}
.hash3 p::first-letter{
  color: red;
  font-size: 200%;
}
.title-text1::first-letter{
  color: red;
  font-size: 150%;
}


.title-box {
    border-radius: 10px;
    padding: 20px;
  }

  .breadcrumb-section {
    font-size: 14px;
    color: #6c757d;
  }

  .hero-section {
    background: linear-gradient(120deg, #f0f8ff, #e6f7ff);
    border-radius: 10px;
  }

  .hero-section h2 {
    font-size: 2.5rem;
    font-weight: bold;
  }

  .card {
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
  }

  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }

  .card-body i {
    font-size: 50px;
    color: #A35C7A;
  }

  .animatable {
    animation-duration: 1s;
    animation-timing-function: ease-out;
  }

  .animate-from-left {
    animation-name: slideInLeft;
  }

  .animate-from-right {
    animation-name: slideInRight;
  }

  @keyframes slideInLeft {
    from {
      opacity: 0;
      transform: translateX(-100px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes slideInRight {
    from {
      opacity: 0;
      transform: translateX(100px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  .header-section {
    background: #A35C7A;
    padding: 15px;
  }

  .breadcrumb-section {
    font-size: 14px;
    color: #6c757d;
  }

  .description-text p {
    font-size: 16px;
    line-height: 1.6;
  }

  .img-fluid {
    max-width: 100%;
    height: auto;
  }

  .animatable {
    animation-duration: 1s;
    animation-timing-function: ease-out;
  }

  .animate-from-left {
    animation-name: slideInLeft;
  }

  .animate-from-right {
    animation-name: slideInRight;
  }

  @keyframes slideInLeft {
    from {
      opacity: 0;
      transform: translateX(-100px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes slideInRight {
    from {
      opacity: 0;
      transform: translateX(100px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }









/* ------------------------------ */
body {
    background-image: url('../../../assets/img/service/background-images.jpg');
    background-size: cover; 
    background-position: center center;  
    background-repeat: no-repeat; 
    
  }
  .container123 img{
    height: 500px;
    width: 100%; 
  }

.left-column {
  width: 48%; /* Takes half the page for larger screens */
  text-align: left;
}

/* Right Column */
.right-column {
  width: 48%; /* Takes the other half of the page for larger screens */
  text-align: left;
}

/* Style for Headings */
.right-aligned {
  color: #A35C7A;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Style for Lists */
ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  padding-left: 1.5rem;
  position: relative;
}

/* Add bullets using ::before */
ul li::before {
  content: '●';
  position: absolute;
  left: 0;
  color: #A35C7A;
  font-size: 1.2rem;
  top: 50%;
  transform: translateY(-50%);
}

/* Add spacing between the first and second sections in the right column */
.right-column p + ul {
  margin-top: 10px;
}

/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
  .left-column,
  .right-column {
    width: 100%; /* Stacks both columns */
    margin-bottom: 20px; /* Adds spacing between stacked sections */
  }
  
  .content-container {
    gap: 0; /* Removes gap for stacked layout */
  }
}
  /* Animation Styles */
.animatable {
  opacity: 0;
  transform: translateX(100px); /* Starts off to the right */
  transition: opacity 1s ease-out, transform 4s ease-out;
}

/* For elements that animate from the right */
.animate-from-right {
  animation: slideInFromRight 4s forwards;
}

/* For elements that animate from the left */
.animate-from-left {
  animation: slideInFromLeft 4s forwards;
}

/* Keyframes for sliding in from the right */
@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Keyframes for sliding in from the left */
@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Optional: Add a delay for the first element */
.animatable.animate-from-right {
  animation-delay: 0.2s;
}

.animatable.animate-from-left {
  animation-delay: 0.4s;
}

/* Other styling for text */
.description-text p {
  font-size: 18px; /* Adjust as per preference */
  color: #333;
  margin: 10px 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .description-text p {
    font-size: 16px; /* Adjust for smaller screens */
  }
}
  /* Global Styles */
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers horizontally */
    justify-content: center; /* Centers vertically */
    min-height: 100vh; /* Ensures full-page height */
    background-color: #f9f9f9; /* Optional background color */
  }

  p {
    text-align: center;
    font-size: 18px; /* Adjust as per preference */
    color: #333; /* Optional text color */
    margin: 10px 0;
  }

  .highlight-text {
    font-weight: bold;
    color: #A35C7A; 
  }

  /* Default Image Styling for all screen sizes */
.responsive-img {
  width: 100%;              
  height: auto;               
  object-fit: cover;        
  max-height: 500px;          
}

/* Mobile and tablet responsiveness */
@media (max-width: 768px) {
  .responsive-img {
    width: 100%;              
    height: auto;             
    object-fit: cover;        
    max-height: 300px;       
  }
}

/* For larger screen sizes like tablets and desktops */
@media (min-width: 769px) and (max-width: 1024px) {
  .responsive-img {
    width: 100%;              
    height: auto;             
    object-fit: cover;       
    max-height: 400px;       
  }
}

/* For desktop view */
@media (min-width: 1025px) {
  .responsive-img {
    width: 90%;              
    height: auto;            
    object-fit: cover;       
    max-height: 700px;        
  }
}


 /* Container to hold bullet points and headings */
.bullet-container {
  display: flex;
  flex-direction: column;  /* Stack items vertically */
  align-items: flex-end;   /* Align items to the right */
  margin-bottom: 30px;     /* Space between sections */
}

.bullet-column {
  display: flex;
  flex-direction: column;
  align-items: flex-end;   /* Align list items to the right */
}

.right-aligned {
  text-align: right;       /* Align text to the right */
  margin-bottom: 10px;     /* Space between heading and list */
}

/* Custom Style for List Items */
.bullet-column ul {
  list-style-type: none;
  padding-left: 0;
  text-align: right;       /* Align the list items to the right */
}

.bullet-column ul li {
  padding-left: 1.5rem;    /* Indentation for bullet points */
  position: relative;
}

.bullet-column ul li::before {
  content: '●';
  position: absolute;
  left: 0;
  color: #A35C7A;
  font-size: 1.2rem;
  top: 50%;
  transform: translateY(-50%);
}

/* Optional: For animation classes */
.animatable {
  transition: all 0.5s ease-in-out;
}

.animate-from-left {
  animation: slideInLeft 1s forwards;
}

.animate-from-right {
  animation: slideInRight 1s forwards;
}

/* Keyframes for animation */
@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

</style>
 