<div class="text-center my-5 container">
  <h2 class="font-weight-bold">LOOKING FOR JOB OPPOURTUNITY</h2>
  <button class="btn-lg" style=" background: linear-gradient(180deg, #C890A7, #A35C7A); color: white; border: none;" routerLink="/upload-cv">
  APPLY HERE
  </button>
  </div>
  <br>
  <div style="flex: 1; height: 2px; background: #ddd; margin: 0 10px; position: relative;">
    <i class="fa fa-rocket" style="position: absolute; top: -12px; left: 50%; transform: translateX(-50%); background: #fff; padding: 5px; color: #A35C7A; border-radius: 50%;"></i>
  </div>
  <br><br>
  <div class="container">
    <div class="row">
      <!-- Vision Section
      <div class="col-12 col-md-6 text-center" style="box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); padding: 20px; border-radius: 8px; margin-bottom: 20px; transition: box-shadow 0.3s ease-in-out;">
        <h3><i class="fa fa-check"></i>&nbsp;Approved by Govt of India</h3>
        <p class="font-weight-bold">&nbsp;&nbsp;B-1717/TN/PER/100/5/10184/2022</p>
      </div> -->
      
      
      <div class="container center-container  col-12 col-md-6" style="box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);text-align: center; padding: 20px; border-radius: 8px; margin-bottom: 20px;  transition: box-shadow 0.3s ease-in-out;">
        <h3><i class="fa fa-phone"></i>&nbsp;Support Center</h3>
        <p class="font-weight-bold">+91 98943 55288</p>
      </div>
    </div>
  </div>
  <br>
<footer class="custom-navbar text-white py-5">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <a class="navbar-brand" href="#">
            <img class="logo-img" src="../../assets/img/icon/image (1).png" width="80px" alt="SB Consultancy" />&nbsp;<h2 class="text-center text-white">Touch With Us</h2>
          </a> 
        </div>
  
        <div class="col-md-4">
          <h5>Important Links</h5>
          <ul class="list-unstyled">
            <li><a href="#" class="text-white">Home</a></li>
            <li><a routerLink="/about-us" routerLinkActive="active" class="text-white">About Us</a></li>
            <li><a routerLink="/web-development" routerLinkActive="active" class="text-white">Services</a></li>
            <li><a routerLink="/verticals" routerLinkActive="active" class="text-white">Industries We Serve</a></li>
            <li><a routerLink="/ourproducts" routerLinkActive="active" class="text-white">Recruitment Process</a></li>
            <li><a routerLink="/upload-cv" routerLinkActive="active" class="text-white">Job Seeker</a></li>
            <li><a routerLink="/contact-us" routerLinkActive="active" class="text-white">Contact</a></li>
          </ul>
        </div>
  
        <!-- Contact Info Section -->
        <div class="col-md-4">
          <h5>Contact Info</h5>
          <p>Akila Complex, Shop No:B11 & B12, Odathurai Street Thiruvaiyaru, Thanjavur - Dt,Pin - 613204, Tamil Nadu.</p>
          <p>+91 98943 55288</p>
          <p><a href="mailto:Info@sbconsultancy.org.in" class="text-white">Info@sbconsultancy.org.in</a></p>
        </div>
      </div>
  
      <!-- Copyright Section -->
      <div class="text-center mt-4">
      
        <a class="mail-call" href="tel:9894355288">
          <span class="fa fa-phone" style="color: #ffffff; font-size: 25px;" aria-hidden="true"></span>&nbsp;&nbsp;&nbsp;
        </a>  
        <a class="mail-call1" href="mailto:Info@sbconsultancy.org.in,mailto:sbconsultancytnj&#64;gmail.com">
          <span class="fa fa-envelope" style="font-size: 25px; color: rgb(255, 255, 255);"></span>&nbsp;&nbsp;&nbsp;
        </a>   
        <a class="mail-call2" href="https://wa.me/9894355288" target="_blank">
          <i class="fa fa-whatsapp" style="color: #ffffff; font-size: 25px;"></i>&nbsp;&nbsp;
        </a>
        <br>
        <br>
        <p>&copy; 2025 SB Consultants. All rights reserved 2025</p>
        <a href="http://annaasilicontechnology.com/#/" target="_blank">
          <p style="font-size: 10px; font-weight: bold; color: #f8f6f6;" class="responsive-text">
            Designed by AnnaaSiliconTechnology.Pvt.Ltd.,®
          </p>
        </a>
      </div>
    </div>
  </footer>
  <style>
    .responsive-text {
      font-size: 5px;
      font-weight: bold;
      color: #f8f6f6;
    }
  
    /* For mobile devices */
    @media (max-width: 576px) {
      .responsive-text {
        font-size: 2px; /* Adjust as needed */
      }
    }
  
    /* For tablets */
    @media (min-width: 577px) and (max-width: 768px) {
      .responsive-text {
        font-size: 5px; /* Adjust as needed */
      }
    }
  
    /* For desktops */
    @media (min-width: 769px) {
      .responsive-text {
        font-size: 30px; /* Default size */
      }
    }
  </style>
  