<div class="title-box uk-border-rounded p-4" >
  <div class="header-section bg-gradient-primary text-black p-4 rounded-top shadow text-center">
    <h1 class="title-text text-black font-weight-bold text-uppercase">EXCLUSIVE TRAINING</h1>
</div>
  </div>

<div class="text-center my-4">
  <img src="../../../assets/img/home/Training People.jpg" alt="Company Image" class="responsive-img">
</div>



<div class="description-text text-justify mx-3">
  <p  class="animatable animate-from-right" style="font-size: 20px;">
    SB Consultants knows the client business needs and know how to provide skilled candidates as per the requirements.
  </p>

  <p class="animatable animate-from-left" style="font-size: 20px;">
    <span>We here at our center in Mumbai conduct various kind of training</span><br>
    <span>sessions to the candidates and help them prepare to present and work well in the company environment.</span>
  </p>
  <p class="animatable animate-from-right" style="font-size: 20px;">
    We give following training :
  </p>
  <h2 class="text-center" style="font-size: 35px; font-weight: bold; color: #000000;">
    Corporate Training
  </h2>

  <div class="text-center my-4">
    <img src="../../../assets/img/home/CorporateTraining.jpg" alt="Company Image" class="responsive-img">
  </div>
  <p class="animatable animate-from-left" style="font-size: 20px;">
    <span>Corporate training plays an important role in the candidate's profile.</span><br>
    <span>Companies require skilled candidates who can excel in their work.</span><br>
    <span>Additionally, candidates must be aware of corporate etiquettes, which help them present themselves professionally in any country.</span>
  </p>
  <div class="text-center my-4" >
    <img src="../../../assets/img/home/CorporateTraining2.jpg" alt="Company Image" class="responsive-img">
  </div>
  <h2 class="text-center" style="font-size: 35px; font-weight: bold; color: #000000;" >
    Soft skills training
  </h2>
  
  

  <div class="skills-container">
    <div class="skills-column">
      <ul class="fade-in-right">
        <li>Body Language</li>
        <li>Rate of Speech</li>
        <li>Voice Modulation</li>
        <li>Listening Skills</li>
        <li>Presentation Skills</li>
        <li>Public Speaking</li>
        <li>Confidence</li>
        <li>Empathy</li>
      </ul>
    </div>
    <div class="skills-column">
      <ul class="fade-in-left">
        <li>Feedback</li>
        <li>Health & Well-being at Workplace</li>
        <li>Challenging Negative Thinking - The Mind Game</li>
        <li>Stress Management</li>
        <li>Healthy Eating & Hydration</li>
        <li>Meditation</li>
        <li>Importance of Body Posture & How to Rectify It</li>
        <li>HIIT & Home Workouts to Stay Fit</li>
      </ul>
    </div>
  </div>
  


  <div class="text-center my-4">
    <img src="../../../assets/img/home/SoftSkills.png" alt="Company Image" class="responsive-img">
  </div>
  <h2 class="text-center" style="font-size: 35px; font-weight: bold; color: #000000;">
    Industrial training
  </h2>
 

   <div class="bullet-container">
    <div class="bullet-column">
      <ul class="animatable animate-from-right">
        <li>Goal Setting</li>
        <li>Role Effectiveness</li>
        <li>Personal Grooming</li>
        <li>Business and Personal Etiquettes</li>
        <li>Stress Management</li>
        <li>Time Management</li>
        <li>Team Building</li>
        <li>Presentation Skills</li>
        <li>Negotiation skills</li>
        <li>Communication For Result</li>
        <li>Crucial Conversations</li>
        <li>Develop Public Speaking</li> 
      </ul>
    </div>
    <div class="bullet-column">
      <ul class="animatable animate-from-left">
        <li>Managing People Effectively</li>
        <li>Conflict Management</li>
        <li>Creative Problem Solving</li>
        <li>Setting up Work Life Equilibrium</li>
        <li>Developing Situational leadership</li>
        <li>Selling Skills</li>
        <li>SPIN Selling</li>
        <li>Train the Trainer</li>
        <li>Campus To Corporate</li>
        <li>Development (For students & Start ups)</li>
        <li>Leadership Development Program.</li>
      </ul>
    </div>
  </div>
  <br>

  <div class="text-center my-4">
    <img src="../../../assets/img/home/IndustrialTraining.png" alt="Company Image" class="responsive-img">
  </div>
  <h2 class="text-center" style="font-size: 35px; font-weight: bold; color: #000000;">
    NDT testing
  </h2>

  <p class="animatable animate-from-right" style="font-size: 20px;">
    <span>We are authorised to conduct ASNT L-II training for UT,RT,MPT and LPT subjects.</span><br>
    <span>We also teach advance NDT techniques as per requirements like TOFD,phase array, Eddy current testing and rope access.</span>
  </p>
  <p class="animatable animate-from-right" style="font-size: 20px;">
    We also conduct training of project management for fresh engineering graduates and diploma holders.
  </p>
  <p class="animatable animate-from-right" style="font-size: 20px;">
    We also have testing facilities for welders, fabricators, electrician, crane operators and riggers.
  </p>
  <div class="text-center my-4">
    <img src="../../../assets/img/home/NDT.jpg" alt="Company Image" class="responsive-img">
  </div>
  <h2 class="text-center" style="font-size: 35px; font-weight: bold; color: #000000;">
    Foreign Language training
  </h2>
  <p class="animatable animate-from-right" style="font-size: 20px;">
    <span>SB consultants also provides various foreign language training which will help employees to connect with other</span><br>
    <span>countries than India .</span>
  </p>
  <div class="text-center my-4">
    <img src="../../../assets/img/home/LangaugeTraining.jpg" alt="Company Image" class="responsive-img">
  </div>
  <br>
  <div style="flex: 1; height: 2px; background: #ddd; margin: 0 10px; position: relative;">
    <i class="fa fa-rocket" style="position: absolute; top: -12px; left: 50%; transform: translateX(-50%); background: #fff; padding: 5px; color: #A35C7A; border-radius: 50%;"></i>
  </div>
  <br>
</div>

<style>
 
 .description-text h2::first-letter{
  color: red;
  font-size: 200%;
 }
.title-box h1:hover{
  color: red;
}
.header-section h1::first-letter{
  color: red;
  font-size: 150%;
}




.skills-container li:hover{
  color: #A35C7A;
}
.skills-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 20px;
}

.skills-column {
  flex: 1;
  min-width: 300px;
  max-width: 400px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul li {
  background: #f8f9fa;
  margin: 10px 0;
  padding: 12px 15px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  text-align: center;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.fade-in-right {
  opacity: 0;
  transform: translateX(50px);
  animation: fadeIn 1s ease-in forwards;
}

.fade-in-left {
  opacity: 0;
  transform: translateX(-50px);
  animation: fadeIn 1s ease-in forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}













.animatable {
  opacity: 0;
  transform: translateX(100px); /* Starts off to the right */
  transition: opacity 1s ease-out, transform 4s ease-out;
}

/* For elements that animate from the right */
.animate-from-right {
  animation: slideInFromRight 4s forwards;
}

/* For elements that animate from the left */
.animate-from-left {
  animation: slideInFromLeft 4s forwards;
}
img{
  box-shadow: 4px 4px 30px grey;
}

/* Keyframes for sliding in from the right */
@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Keyframes for sliding in from the left */
@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Optional: Add a delay for the first element */
.animatable.animate-from-right {
  animation-delay: 0.2s;
}

.animatable.animate-from-left {
  animation-delay: 0.4s;
}

/* Other styling for text */
.description-text p {
  font-size: 18px; /* Adjust as per preference */
  color: #333;
  margin: 10px 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .description-text p {
    font-size: 16px; /* Adjust for smaller screens */
  }
}
  /* Global Styles */
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers horizontally */
    justify-content: center; /* Centers vertically */
    min-height: 100vh; /* Ensures full-page height */
    background-color: #f9f9f9; /* Optional background color */
  }

  p {
    text-align: center;
    font-size: 18px; /* Adjust as per preference */
    color: #333; /* Optional text color */
    margin: 10px 0;
  }

  .highlight-text {
    font-weight: bold;
    color: #007bff; 
  }

  /* Default Image Styling for all screen sizes */
.responsive-img {
  width: 100%;              
  height: auto;               
  object-fit: cover;        
  max-height: 800px;          
}

/* Mobile and tablet responsiveness */
@media (max-width: 768px) {
  .responsive-img {
    width: 90%;              
    height: auto;             
    object-fit: cover;        
    max-height: 300px;       
  }
}

/* For larger screen sizes like tablets and desktops */
@media (min-width: 769px) and (max-width: 1024px) {
  .responsive-img {
    width: 90%;              
    height: auto;             
    object-fit: cover;       
    max-height: 800px;       
  }
}

/* For desktop view */
@media (min-width: 1025px) {
  .responsive-img {
    width: 90%;              
    height: auto;            
    object-fit: cover;       
    max-height: 700px;        
  }
}


  /* Bullet Container */
  .bullet-container {
    display: flex;
    justify-content: space-between;
    gap: 20px; 
    max-width: 900px;
    margin: 20px auto; 
  }

  /* Bullet Column Styling */
  .bullet-column {
    flex: 1;
    list-style: none; 
    padding: 0;
  }

  .bullet-column ul {
    padding-left: 50px; 
    margin: 0;
  }

  .bullet-column li {
    position: relative;
    margin-bottom: 10px; 
    font-size: 16px;
    font-family: Arial, sans-serif;
    color: #000;
    line-height: 1.6;
  }

  /* Custom Bullet Design */
  .bullet-column li::before {
    /* content: '●';  */
    position: absolute;
    left: -20px;
    /* color: #007bff; */
    font-size: 20px;
  }

  /* Media Queries for Mobile */
  @media (max-width: 768px) {
    .bullet-container {
      flex-direction: column; 
      gap: 10px; 
      margin: 10px auto; 
    }

    .bullet-column {
      padding-left: 10px;
    }

    .bullet-column ul {
      padding-left: 30px; 
    }

    .bullet-column li {
      font-size: 14px; 
      margin-bottom: 8px; 
    }

    .bullet-column li::before {
      left: -15px; 
      font-size: 18px; 
    }
  }

</style>