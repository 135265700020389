<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Home Page</title>
  <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css" rel="stylesheet">
  <link href="https://cdn.jsdelivr.net/npm/animate.css" rel="stylesheet">
  <link rel="stylesheet" href="home-page.component.scss">
</head>
<body>
  <div class="text-center mt-4 container2">
    <a class="mail-call"  href="tel:9894355288">
      <span class="fa fa-phone phone-custom" style="color: #C890A7;background: linear-gradient(#C890A7, #A35C7A); font-size: 20px; text-decoration: none ;padding: 3px; color: white;" aria-hidden="true"></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </a>  
    <a class="mail-call1" href="mailto:Info@sbconsultancy.org.in,mailto:sbconsultancytnj&#64;gmail.com">
      <span class="fa fa-envelope" style="font-size: 20px;  color: #C890A7;background: linear-gradient(#C890A7, #A35C7A); color: white; padding: 3px; "></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </a>   
    <a class="mail-call2" href="https://wa.me/9894355288" target="_blank">
      <i class="fab fa-whatsapp" style="color: #C890A7;background: linear-gradient(#C890A7, #A35C7A); color: white;; font-size: 20px; padding: 3px;"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </a>
    <a class="mail-call3" href="https://www.instagram.com/sbc3600?utm_source=qr&igsh=eDRnM2xrbXo2aDUx" target="_blank">
      <i class="fab fa-instagram" style="color: #C890A7;background: linear-gradient(#C890A7, #A35C7A); color: white; font-size: 20px; padding: 3px;"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </a>
    <a class="mail-call4" href="https://www.facebook.com/profile.php?id=61572205600780" target="_blank">
      <i class="fab fa-facebook" style="color: #C890A7;background: linear-gradient(#C890A7, #A35C7A); color: white;; font-size: 20px; padding: 3px; "></i>&nbsp;&nbsp;
    </a>
  </div>
  <div class="slideshow-container">
    <div class="slide" *ngFor="let slide of slides; let i = index" [class.active]="i === currentIndex">
      <img [src]="slide.image" alt="Slide Image" class="slide-image" />
      <div class="caption">{{ slide.caption }}</div>
    </div>
  </div>
  <div class="gallery-container">
    <!-- <div *ngIf="images.length === 0">No images available.</div> -->
    <div *ngFor="let image of images" class="image-item">
      <div class="image-wrapper">
        <img [src]="image.filePath" [alt]="image.name" />
      </div>
      <!-- <p>{{ image.name }}</p> -->
    </div>
  </div>
  <br>
  <br>
  <div class="row text-center">
    <div class="col-md-4 col-sm-12 my-3">
      <div class="card border-0 shadow-lg hover-card">
        <div class="card-body">
          <div class="icon-container text-primary">
            <i class="fa fa-lightbulb-o"></i>
          </div>
          <h5 class="card-title font-weight-bold mt-3">ABOUT US</h5>
          <p class="card-text text-muted">
            Explore cutting-edge ideas that drive growth and efficiency.
          </p>
          <button class="btn btn-outline-primary mt-3" routerLink="/about-us" style="color: white ; background-color: #A35C7A; border: none;">
            Discover More
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-12 my-3">
      <div class="card border-0 shadow-lg hover-card">
        <div class="card-body">
          <div class="icon-container text-success">
            <i class="fa fa-cogs"></i>
          </div>
          <h5 class="card-title font-weight-bold mt-3">RECRUITMENT</h5>
          <p class="card-text text-muted">
            Benefit from our high-performance solutions tailored to you.
          </p>
          <button  style="color: white ; background-color: #A35C7A; border: none;" class="btn btn-outline-primary mt-3" routerLink="/ourproducts">
            Discover More
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-12 my-3">
      <div class="card border-0 shadow-lg hover-card">
        <div class="card-body">
          <div class="icon-container text-danger">
            <i class="fa fa-user"></i>
          </div>
          <h5 class="card-title font-weight-bold mt-3">INDUSTRIES</h5>
          <p class="card-text text-muted">
            Enjoy 24/7 customer support for a seamless experience.
          </p>
          <button class="btn btn-outline-primary mt-3" routerLink="/verticals"  style="color: white ; background-color: #A35C7A; border: none;">
            Discover More
          </button>
        </div>
      </div>
    </div>
    <!-- Add similar cards for other products here -->
  </div>
  <br>
  <div style="flex: 1; height: 2px; background: #ddd; margin: 0 10px; position: relative;">
    <i class="fa fa-rocket" style="position: absolute; top: -12px; left: 50%; transform: translateX(-50%); background: #fff; padding: 5px; color: #A35C7A; border-radius: 50%;"></i>
  </div>
  <br>
  <div class="container">
    <div class="row">
      <!-- Vision Section -->
      <div class="col-md-6 text-start">
        <h3><i class="fa fa-eye"></i>&nbsp;OUR VISION</h3>
        <p class="font-weight-bold">To be leading partner build tangible assets of talents</p>
      </div>
  
      <!-- Mission Section -->
      <div class="col-md-6 text-end">
        <h3><i class="fa fa-rocket"></i>&nbsp;OUR MISSION</h3>
        <p class="font-weight-bold">We achieve our vision through collaboration, passion, and adaptability in the first time and every time.</p>
      </div>
    </div>
  </div>
  <br>
  <!-- <div  style="flex: 1; height: 2px; background: #ddd; margin: 0 10px;" ></div> -->
  <div style="flex: 1; height: 2px; background: #ddd; margin: 0 10px; position: relative;">
    <i class="fa fa-rocket" style="position: absolute; top: -12px; left: 50%; transform: translateX(-50%); background: #fff; padding: 5px; color: #A35C7A; border-radius: 50%;"></i>
  </div>
  <div class="text-center my-5 container5">
    <h2 class="font-weight-bold">Shape the Future with Us</h2>
    <p class="text-muted lead font-weight-bold">
      Contact us today how we can help you achieve your goals.
    </p>
    <button class="btn btn-primary btn-lg" routerLink="/contact-us" style="color: white ; background-color: #A35C7A; border: none;">
      Contact Us
    </button>
    </div>
    <br>
    <div style="flex: 1; height: 2px; background: #ddd; margin: 0 10px; position: relative;">
      <i class="fa fa-rocket" style="position: absolute; top: -12px; left: 50%; transform: translateX(-50%); background: #fff; padding: 5px; color: #A35C7A; border-radius: 50%;"></i>
    </div>
    <br><br>
    <h2 class="bg-1 text-center">INDUSTRIES</h2>
    <div class="container-img row">
      <div class="text-center my-4">
        &nbsp;&nbsp; &nbsp;&nbsp;<img style="width: 300px; height: 300px; " src="../../assets/img/home/it.jpg" alt="Company Image" class="responsive-img">
      </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <div class="text-center my-4">
        <img style="width: 300px; height: 300px; " src="../../assets/img/home/construction.jpg" alt="Company Image" class="responsive-img">
      </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <div class="text-center my-4">
        <img style="width: 300px; height: 300px; " src="../../assets/img/home/manufacture.jpg" alt="Company Image" class="responsive-img">
      </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <div class="text-center my-4">
        <img style="width: 300px; height: 300px; " src="../../assets/img/home/oil.jpg" alt="Company Image" class="responsive-img">
      </div>
    <div class="text-center my-4">
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <img style="width: 300px; height: 300px; " src="../../assets/img/home/sb images.jpg" alt="Company Image" class="responsive-img">
      </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <div class="text-center my-4">
        <img style="width: 300px; height: 300px; " src="../../assets/img/home/sb images1.jpg" alt="Company Image" class="responsive-img">
      </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <div class="text-center my-4">
        <img style="width: 300px; height: 300px; " src="../../assets/img/home/sb images2.jpg" alt="Company Image" class="responsive-img">
      </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <div class="text-center my-4">
        <img style="width: 300px; height: 300px; " src="../../assets/img/home/sb images3.jpg" alt="Company Image" class="responsive-img">
      </div>
  
     </div>
     <br>
     <div style="flex: 1; height: 2px; background: #ddd; margin: 0 10px; position: relative;">
      <i class="fa fa-rocket" style="position: absolute; top: -12px; left: 50%; transform: translateX(-50%); background: #fff; padding: 5px; color: #A35C7A; border-radius: 50%;"></i>
    </div>
</body>
</html>
