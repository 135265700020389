<div class="container">
  <h1><span class="font-weight-bold highlight1 text-center">O</span>VERSEAS <span class="highlight">R</span>ECRUITMENT</h1>
  <div class="hero-section">
    <div class="hero-content">
      <h1 class="hero-title">Your Gateway to Global Recruitment</h1>
      <p class="hero-description">Shaping futures by uniting talent with the right roles</p>
      <a href="#text2" class="cta-button">EXPLORE OUR SERVICES</a>
    </div>
  </div>


  <div id="services" class="services-section">
    <h1><span class="font-weight-bold highlight">O</span>UR <span class="highlight">E</span>XPERTISE</h1>
    <br>
    <div class="text1">
      <img src="../../../assets/img/service/overseasimagesservice.jpg">
     </div>
     <br>
     <br>
     <p class="font-weight-bold" style="text-align: justify; font-size: 20px">
      We provide end-to-end overseas recruitment services, from talent acquisition to seamless onboarding in countries worldwide.
      Whether you're looking for qualified professionals to join your team or seeking a rewarding career abroad, we’ve got you covered.
  </p>
  <p class="font-weight-bold" style="text-align: justify; font-size: 20px">
      Let SB Consultancy help you build a diverse and skilled workforce by recruiting top professionals from around the world.
      We specialize in sourcing candidates who are not only highly skilled but also culturally adaptable to thrive in international environments.
  </p>  
     <br>
  <div style="flex: 1; height: 2px; background: #ddd; margin: 0 10px; position: relative;">
    <i class="fa fa-rocket" style="position: absolute; top: -12px; left: 50%; transform: translateX(-50%); background: #fff; padding: 5px; color: #A35C7A; border-radius: 50%;"></i>
  </div>
  <br>
  <br>
    <div class="cards-container" id="text2">
      <div class="service-card">
        <div class="card-icon">
          <i class="fa fa-briefcase"></i>
        </div>
        <h3 class="card-title">Domestic Recruitment</h3>
        <p class="card-description">We specialize in hiring skilled professionals for businesses across India.</p>
      </div>
      <!-- Service Card 2 -->
      <div class="service-card">
        <div class="card-icon">
          <i class="fa fa-globe"></i>
        </div>
        <h3 class="card-title">International Recruitment</h3>
        <p class="card-description">Connecting companies with global talent, ensuring quality and compliance.</p>
      </div>
      <!-- Service Card 3 -->
      <div class="service-card">
        <div class="card-icon">
          <i class="fa fa-cogs"></i>
        </div>
        <h3 class="card-title">Industrial Staffing</h3>
        <p class="card-description">Providing industry-specific staffing solutions for major sectors like Oil, Gas, and IT.</p>
      </div>
    </div>
  </div>


</div>

<style>
  /* Global Styles */
  /* Global Styles */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #1d1d1d;  /* Dark background */
  margin: 0;
  padding: 0;
  color: #f0f0f0;  /* Light text color */
}
.highlight1 {
            color: red; 
            font-size: 200%;
            font-weight: bold;
        }
        .highlight {
            color:red;
            font-size: 200%;
            font-weight: bold;
        }
        h1 {
            text-align: center;
        }



.text1{
  align-items: center;
  text-align: center;
  justify-content: center;
}
.text1 img{
  box-shadow: 4px 4px 30px grey;
  height: 500px;
  width: 500px;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Hero Section */
.hero-section {
  background: linear-gradient(180deg, #C890A7, #A35C7A);  
  color: white;
  padding: 100px 20px;
  text-align: center;
  position: relative;
  border-radius: 10px;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
}

.hero-title {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 20px;
}

.hero-description {
  font-size: 20px;
  margin-bottom: 30px;
}

.cta-button {
  background-color: #000000;
  color: white;
  padding: 15px 25px;
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #e67e22;
}

/* Service Cards Section */
.services-section {
  padding: 50px 0;
  text-align: center;
}

.section-title {
  font-size: 36px;
  margin-bottom: 30px;
  color: #ecf0f1;  /* Light text color for contrast */
}

.cards-container {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.service-card {
  background-color: #A35C7A;  /* Dark card background */
  border-radius: 10px;
  padding: 40px;
  width: 30%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);  /* Darker shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);  /* Darker hover shadow */
}

.card-icon {
  font-size: 50px;
  margin-bottom: 20px;
  color: #ecf0f1;  /* Light icon color */
}

.card-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #fafeff;  /* Light card title */
}

.card-description {
  font-size: 16px;
  color: #ffffff;  /* Lighter description */
}

/* About Us Section */
.about-us-section {
  background-color: #A35C7A;  /* Darker background */
  padding: 50px 20px;
  margin-top: 50px;
  border-radius: 10px;
}

.about-content {
  display: flex;
  align-items: center;
  gap: 30px;
}

.about-img {
  width: 50%;
  border-radius: 10px;
}

.about-text {
  width: 50%;
  color: #ecf0f1;  /* Light text for contrast */
}

/* Contact Section */
.contact-section {
  background-color: #A35C7A;  /* Dark background */
  padding: 50px 20px;
  margin-top: 50px;
  border-radius: 10px;
  text-align: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 500px;
  margin: 0 auto;
}

.form-input, .form-textarea {
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #7f8c8d;  /* Light border for inputs */
  font-size: 16px;
  color: #ecf0f1;  /* Light text in inputs */
  background-color: #34495e;  /* Dark background for inputs */
}

.form-textarea {
  height: 150px;
  resize: none;
}

.form-input:focus, .form-textarea:focus {
  outline: none;
  border-color: #f39c12;  /* Bright focus border */
}

.contact-form button {
  background-color: #9db9cc;
  color: white;
  padding: 15px;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #3498db;
}
.services-section h1 .highlight {
  color: red;
  font-size: 200%;
  font-weight: bold; /* Optional: to make it bold */
}
@media (max-width: 768px){
  .text1 img{
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 300px;
    height: 300px;
  }
}

@media (max-width: 768px) {
  .cards-container {
    flex-direction: column;
    gap: 20px;
  }

  .service-card {
    width: 100%;
  }

  .about-content {
    flex-direction: column;
    text-align: center;
  }

  .about-img {
    width: 80%;
    margin-bottom: 20px;
  }

  .about-text {
    width: 100%;
  }
}

</style>
