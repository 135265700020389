import { Component, OnInit } from '@angular/core';
import { GalleryPageServiceService } from './gallery-page-service.service';


declare var $: any;
@Component({
  selector: 'app-gallery-page',
  templateUrl: './gallery-page.component.html',
  styleUrls: ['./gallery-page.component.scss']
})
export class GalleryPageComponent implements OnInit {

  images: any[] = [];

  constructor(private GalleryPageService: GalleryPageServiceService) {}

  ngOnInit(): void {
    this.loadGallery();
  }

  loadGallery(): void {
    this.GalleryPageService.getGallery().subscribe(
      (data) => {
        this.images = data;
      },
      (error) => {
        console.error('Error loading gallery', error);
      }
    );
  }
}
