<div class=" d-none d-sm-block">

<div class="max-img">
  <img src="../../assets/img/about-us/sb about-us.jpg" class="responsive-img" width="700px" height="520px" alt="">
  <h2 class="text-center" style="position: absolute; top: 20%; right: 210px; color: rgb(0, 0, 0); font-size: 35px;">ABOUT US</h2>
  <p  style="position: absolute; top: 28%; left: 750px; color: rgb(0, 0, 0);">
    SB Consultancy, a leading International Manpower Consultant. 
    We as SB Consultancy are into Recruitment and manpower supply.
     We are a team with a presence in the recruitment sphere for the
      last five years and now delivering globally to achieve the client’s goal and vision.
  </p>
  <button class="button">
    GET IN TOUCH
  </button>
  
  <h3 class="heading">
    WE ARE SB CONSULTANCY YOUR TRUSTED GROWTH PARTNER.....
  </h3>
</div>
<br>


<div class="max-img1">
  <img src="../../assets/img/about-us/about-us images.jpg" height="500px" width="650px" alt="">
  <h2 class="text-center" style="position: absolute; top: 110%; right: 890px;
  background: linear-gradient(180deg, #C890A7, #A35C7A); color: white; border-radius: 20PX; padding: 10PX; font-size: 30px;">OUR TEAM</h2>
  <P  style="position: absolute; top: 125%; right: 760px; color: rgb(0, 0, 0); margin-left: 140px; font-size: 20px;">
    A team of right people is one of the most important elements and ingredients for the success of any business. 
    Therefore, our endeavour is to hunt for “right people" to enhance business growth of our esteemed clients by 
    connecting them with the right kind of skilled people who can drive their organizations on their way of progress.
  </P>
</div>



<div class="max-img2">
  <img src="../../assets/img/about-us/about-us location1.webp" height="400px" width="400px" alt="">
  <h3 class="text-center font-weight-bold" style="position: absolute; top: 190%; right: 180px; color: rgb(0, 0, 0);font-size: 35px;">
    A Word From Managing Director</h3>
  <p class="text-end" style="position: absolute; top: 200%; right: 80px; margin-left: 480px; font-size: 20px; color: rgb(0, 0, 0);">
    SB Consultancy continues to grow every day, thanks to the 
    confidence and trust that our clients have in us and in our team,
    who have the ability to work together towards a common vision. Their efforts act as a fuel that allows our organisation
     to obtain phenomenal results.
  </p>
  <button class="max-img3 font-weight-bold" style="position: absolute; top: 225%; right: 380px; 
  border: 5px solid #A35C7A; font-size: 20px; color: rgb(0, 0, 0);  border-radius: 40% 20%; padding: 10px;"
   routerLink="/contact-us" routerLinkActive="active">KNOW MORE</button>
</div>
<br>

<div style="background: linear-gradient(180deg, #C890A7, #A35C7A); color: white; padding: 30px; border-radius: 15px; text-align: center; max-width: 600px; 
margin: 50px auto; position: relative;">
  <h2
    class="font-weight-bold"
    style="
      font-size: 24px;
      margin-bottom: 20px;
      color: white;
    " >
    NEED MORE INFORMATION
  </h2>
  <h5  style="margin: 10px 0; background: none; border: none; padding: 0; cursor: pointer;"  
    routerLink="/contact-us" routerLinkActive="active">I AM A JOB SEEKER&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button><i class="fa fa-arrow-right"></i></button></h5>
  <h5 style="margin: 10px 0;">JOB CONSULTANCY SUPPORTS YOU</h5>
</div>



<br>
 <div class="vision-mission">
  <div class="box animatable" (mouseenter)="addAnimation($event)" (mouseleave)="removeAnimation($event)">
    <i class="fa fa-eye icon-large" aria-hidden="true"></i>
    <h3>Vision</h3>
    <p>To be the Leading Human Resources provider Globally.</p>
  </div>
  <div class="box animatable" (mouseenter)="addAnimation($event)" (mouseleave)="removeAnimation($event)">
    <i class="fa fa-certificate icon-large" aria-hidden="true"></i>
    <h3>Mission</h3>
    <p>
      To be an innovative service provider by facilitating Right & Prompt Recruitment
      Solutions of the Positions as desired by our Clients.
    </p>
  </div>
  <div class="box animatable" (mouseenter)="addAnimation($event)" (mouseleave)="removeAnimation($event)">
    <i class="fa fa-users icon-large" aria-hidden="true"></i>
    <h3>Our People</h3>
    <p>
      With their expert knowledge and valuable experience, our Team carries expertise in hiring in various
      domains with excellent closure rates.
    </p>
  </div>
</div>
 <br>
 <br>
 <div class="contact-info">
  <a href="tel:9894355288" class="contact-btn phone-btn">
    <span class="fa fa-phone"></span> Call Now
  </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <a href="mailto:Info@sbconsultancy.org.in,mailto:sbconsultancytnj&#64;gmail.com" class="contact-btn email-btn">
    <span class="fa fa-envelope"></span> Email Us
  </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <a href="https://wa.me/9894355288" target="_blank" class="contact-btn whatsapp-btn">
    <span class="fa fa-whatsapp"></span> WhatsApp
  </a>
</div>
<div style="flex: 1; height: 2px; background: #ddd; margin: 0 10px; position: relative;">
  <i class="fa fa-rocket" style="position: absolute; top: -12px; left: 50%; transform: translateX(-50%); background: #fff;
   padding: 5px; color: #A35C7A; border-radius: 50%;"></i>
</div>

</div>






<!-- ---------------------------------MOBILE VIEW------------------------------------ -->
<div class=" d-block d-sm-none">
  <div class="max-img-mobile">
    <br>
    <h1 class="text-center">ABOUT US</h1>
    <img src="../../assets/img/about-us/sb about-us.jpg" class="responsive-img-mobile" style="margin-left: 30px; border-radius: 10px" width="80%" height="50%">
    <br>
    <!-- <h2 class="text-center-mobile">ABOUT US</h2> -->
    <p class="text-center" style="font-size: 20px;">
      SB Consultancy, a leading International Manpower Consultant. 
      We as SB Consultancy are into Recruitment and manpower supply.
       We are a team with a presence in the recruitment sphere for the
        last five years and now delivering globally to achieve the client’s goal and vision.
    </p>
    <button class="button-mobile" style="background: linear-gradient(180deg, #C890A7, #A35C7A); color: white; margin-left: 100px">
      GET IN TOUCH
    </button>
    <br>
    <br>
    <h3 class="heading-mobile" style="font-size: 20px; text-align: center;">
      WE ARE SB CONSULTANCY YOUR TRUSTED GROWTH PARTNER.....
    </h3>
  </div>
<br>

  <div class="max-img1-mobile">
    <h4 class="text-center font-weight-bold" style="font-size: 25px;" >OUR TEAM</h4>
    <img src="../../assets/img/about-us/about-us images.jpg" height="50%" width="80%" style="margin-left: 30px; border-radius: 10px;" alt="">
    <P style="font-size: 20px; text-align: center;">
      A team of right people is one of the most important elements and ingredients for the success of any business. 
      Therefore, our endeavour is to hunt for “right people" to enhance business growth of our esteemed clients by 
      connecting them with the right kind of skilled people who can drive their organizations on their way of progress.
    </P>
  </div>



  <div class="max-img2-mobile">
    <img src="../../assets/img/about-us/about-us location1.webp" style="margin-left: 30px;" height="50%" width="80%" alt="">
    <h3 class="text-center">
      A Word From Managing Director</h3>
    <p class="text-center" style="font-size: 20px;">
      SB Consultancy continues to grow every day, thanks to the 
      confidence and trust that our clients have in us and in our team,
      who have the ability to work together towards a common vision. Their efforts act as a fuel that allows our organisation
       to obtain phenomenal results.
    </p>
    <button class="text-center font-weight-bold button-mobile"
     routerLink="/contact-us" routerLinkActive="active" style="background: linear-gradient(180deg, #C890A7, #A35C7A); color: white; border-radius: 5px;">KNOW MORE</button>
  </div>



  <div style="background: linear-gradient(180deg, #C890A7, #A35C7A); color: white; padding: 30px; border-radius: 15px; text-align: center; max-width: 600px; 
margin: 50px auto; position: relative;">
  <h2
    class="font-weight-bold"
    style="
      font-size: 24px;
      margin-bottom: 20px;
      color: white;
    " >
    NEED MORE INFORMATION
  </h2>
  <h5  style="margin: 10px 0; background: none; border: none; padding: 0; cursor: pointer;"  
    routerLink="/contact-us" routerLinkActive="active">I AM A JOB SEEKER&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button><i class="fa fa-arrow-right"></i></button></h5>
  <h5 style="margin: 10px 0;">JOB CONSULTANCY SUPPORTS YOU</h5>
</div>




<div class="vision-mission">
  <div class="box animatable" (mouseenter)="addAnimation($event)" (mouseleave)="removeAnimation($event)">
    <i class="fa fa-eye icon-large" aria-hidden="true"></i>
    <h3>Vision</h3>
    <p>To be the Leading Human Resources provider Globally.</p>
  </div>
  <div class="box animatable" (mouseenter)="addAnimation($event)" (mouseleave)="removeAnimation($event)">
    <i class="fa fa-certificate icon-large" aria-hidden="true"></i>
    <h3>Mission</h3>
    <p>
      To be an innovative service provider by facilitating Right & Prompt Recruitment
      Solutions of the Positions as desired by our Clients.
    </p>
  </div>
  <div class="box animatable" (mouseenter)="addAnimation($event)" (mouseleave)="removeAnimation($event)">
    <i class="fa fa-users icon-large" aria-hidden="true"></i>
    <h3>Our People</h3>
    <p>
      With their expert knowledge and valuable experience, our Team carries expertise in hiring in various
      domains with excellent closure rates.
    </p>
  </div>
</div>

<br>
<br>
<br>
<div class="contact-info">
  <a href="tel:9894355288" class="contact-btn phone-btn">
    <span class="fa fa-phone"></span> Call Now
  </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <a href="mailto:Info@sbconsultancy.org.in,mailto:sbconsultancytnj&#64;gmail.com" class="contact-btn email-btn">
    <span class="fa fa-envelope"></span> Email Us
  </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <a href="https://wa.me/9894355288" target="_blank" class="contact-btn whatsapp-btn">
    <span class="fa fa-whatsapp"></span> WhatsApp
  </a>
</div>
<div style="flex: 1; height: 2px; background: #ddd; margin: 0 10px; position: relative;">
  <i class="fa fa-rocket" style="position: absolute; top: -12px; left: 50%; transform: translateX(-50%); background: #fff;
   padding: 5px; color: #A35C7A; border-radius: 50%;"></i>
</div>


</div>