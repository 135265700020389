<div class="title-box uk-border-rounded p-4">
  <div class="header-section">
    <h1 class="title-text text-center font-weight-bold">PROFESSIONAL STAFFING</h1>
  </div>
  <!-- <div class="breadcrumb-section">
    <h5 class="text-muted">
        <span class="sub-menu text-primary text-end" style="text-decoration: none;" routerLink="/home-page">Home</span>&nbsp;/&nbsp;
        <span class="sub-menu text-primary text-end" style="text-decoration: none; " routerLink="/web-hosting">Payroll Management</span>
      </h5>
  </div> -->
</div>

<div class="image-section">
  <img src="../../../assets/img/service/professionalstaffingimages.jpg" alt="Company Image">
  <img src="../../../assets/img/service/professionalstaffingimages2.jpg" alt="">
  <img src="../../../assets/img/service/professionalstaffingimages3.jpg" alt="">
  <img src="../../../assets/img/service/professionalstaffingimages4.jpg" alt="">

</div>

<div class="description-text">
  <p class="description-text1 animatable animate-from-right font-weight-bold">
    SB consultants believe in delivering to our clients with the best opportunities possible to achieve gainful, fulfilling employment.
  </p>
  <p class="animatable animate-from-left font-weight-bold">
    It is very important to have a cooperative relationship between employees and employers, which is why we are committed to provide you with a talented, hardworking and dedicated employees for you staffing needs.
  </p> 
  <p class="animatable animate-from-right font-weight-bold">
    If you’re expanding your business, starting a new business, increasing your work load, we can help you find talent quickly.
  </p>
</div>


<div class="industry-section">
	<h2 class="section-title">PROFESSIONAL STAFFING SOLUTIONS</h2>
	<div class="bullet-grid-container">
		<div class="bullet-grid-item">
			<div class="icon-box1">
				<img src="../../../assets/img/service/professionalstaffinglogo1.jpg" alt="Staffing Icon" class="responsive-img" />
			</div>
			<h3>Skilled Workforce</h3>
			<p>Providing highly qualified professionals across various industries to meet your business needs.</p>
		</div>
		<div class="bullet-grid-item">
			<div class="icon-box2">
				<img src="../../../assets/img/service/professionalstaffinglogo2.jpg" alt="Recruitment Icon" class="responsive-img" />
			</div>
			<h3>Expert Recruitment</h3>
			<p>Our team specializes in sourcing top-tier talent with the right skills and experience for your organization.</p>
		</div> 
		<div class="bullet-grid-item">
			<div class="icon-box3">
				<img src="../../../assets/img/service/professionalstaffinglogo3.jpg" alt="Training Icon" class="responsive-img" />
			</div>
			<h3>Customized Training Programs</h3>
			<p>Offering tailored training solutions to ensure your staff excels in their roles.</p>
		</div>
		<div class="bullet-grid-item">
			<div class="icon-box4">
				<img src="../../../assets/img/service/professionalstaffinglogo4.jpg" alt="HR Solutions Icon" class="responsive-img" />
			</div>
			<h3>HR Consultancy</h3>
			<p>Providing expert HR services to optimize your workforce and drive business success.</p>
		</div>
	</div>
</div>
<div style="flex: 1; height: 2px; background: #ddd; margin: 0 10px; position: relative;">
<i class="fa fa-rocket" style="position: absolute; top: -12px; left: 50%; transform: translateX(-50%); background: #fff; padding: 5px; color: ##A35C7A; border-radius: 50%;"></i>
</div>
<br>



<style>
  /* Main Container */
.content-container {
  display: flex;
  justify-content: space-between; 
  gap: 20px; 
  flex-wrap: wrap;
  padding: 20px;
}


@keyframes blink{
  50%{
    opacity: 0;
  }
}
.header-section h1::first-letter{
 color: red;
 font-size: 150%;
}
.industry-section h2{
    animation: blink 1s infinite;
    color: #A35C7A;
}
@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-10px); }
  50% { transform: translateX(10px); }
  75% { transform: translateX(-10px); }
  100% { transform: translateX(10px); }
}
.responsive-img:hover {
  animation: shake 0.5s ease-in-out;
}


.industry-section {
    padding: 40px 0;
    background-color: #f4f4f4;
}
.industry-section .bullet-grid-item{
  box-shadow: 4px 4px 30px #FBF5E5;
}
/* . */

.section-title {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 30px;
}

/* Bullet Grid Container */
.bullet-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 0 20px;
}

/* Bullet Grid Item */
.bullet-grid-item {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.bullet-grid-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.icon-box {
    margin-bottom: 15px;
}

.icon-box img {
    max-width: 50px;
    margin: 0 auto;
}

.bullet-grid-item h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #444;
    margin-bottom: 10px;
}

.bullet-grid-item p {
    font-size: 1rem;
    color: #666;
    line-height: 1.5;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .bullet-grid-container {
        padding: 0 10px;
    }

    .bullet-grid-item {
        padding: 15px;
    }

    .section-title {
        font-size: 1.5rem;
    }
}








.description-text p::first-letter{
  color: red;
  font-size: 150%;
}
.image-section img{
  height: 300px;
  width: 300px;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 20px;
}

.title-box {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
}

.header-section {
  /* background: linear-gradient(135deg, #5f7cff, #000000); */
  color: rgb(0, 0, 0);
  padding: 15px;
  text-align: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
}

.header-section:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  z-index: 0;
}

.title-text {
  font-size: 50px;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  position: relative;
  z-index: 1; /* Ensures text appears on top of the circle */
  animation: fadeIn 1s ease-in-out; /* Fade-in effect */
}

/* Breadcrumb Section */
.breadcrumb-section {
  margin-top: 20px;
  text-align: center;
}

.sub-menu {
  color: #6c757d;
  text-decoration: none;
}

.sub-menu:hover {
  text-decoration: underline;
}

/* Keyframe animation for fade-in effect */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive image */
.image-section {
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 30px;
}

.responsive-img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Description Section */
.description-text {
  text-align: left;
  margin: 20px 30px;
  font-size: 16px;
  line-height: 1.6;
}













/* ----------------------------------------------------------------------- */
.left-column {
  width: 48%; /* Takes half the page for larger screens */
  text-align: left;
}

/* Right Column */
.right-column {
  width: 48%; /* Takes the other half of the page for larger screens */
  text-align: left;
}

/* Style for Headings */
.right-aligned {
  color: #007bff;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Style for Lists */
ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  padding-left: 1.5rem;
  position: relative;
}

/* Add bullets using ::before */
ul li::before {
  content: '●';
  position: absolute;
  left: 0;
  color: #007bff;
  font-size: 1.2rem;
  top: 50%;
  transform: translateY(-50%);
}

/* Add spacing between the first and second sections in the right column */
.right-column p + ul {
  margin-top: 10px;
}


@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}


/* Responsive Design */
@media (max-width: 768px) {
  .description-text p {
    font-size: 16px; /* Adjust for smaller screens */
  }
}
 
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers horizontally */
    justify-content: center; /* Centers vertically */
    min-height: 100vh; /* Ensures full-page height */
    background-color: #f9f9f9; /* Optional background color */
  }

  p {
    text-align: center;
    font-size: 18px; /* Adjust as per preference */
    color: #333; /* Optional text color */
    margin: 10px 0;
  }

  .highlight-text {
    font-weight: bold;
    color: #007bff; 
  }

  /* Default Image Styling for all screen sizes */
.responsive-img {
  width: 100%;              
  height: auto;               
  object-fit: cover;        
  max-height: 500px;          
}
@media (max-width:768px) {
  .header-section {
    padding-right: 0;
    text-align: center; 
  }

  .title-text {
    font-size: 1.5rem; 
}
}

@media (max-width: 768px) {
  .responsive-img {
    width: 100%;              
    height: auto;             
    object-fit: cover;        
    max-height: 300px;       
  }
}

/* For larger screen sizes like tablets and desktops */
@media (min-width: 769px) and (max-width: 1024px) {
  .responsive-img {
    width: 80%;              
    height: auto;             
    object-fit: cover;       
    max-height: 800px;       
  }
}

/* For desktop view */
@media (min-width: 1025px) {
  .responsive-img {
    width: 80%;              
    height: auto;            
    object-fit: cover;       
    max-height: 500px;        
  }
}


 /* Container to hold bullet points and headings */
.bullet-container {
  display: flex;
  flex-direction: column;  /* Stack items vertically */
  align-items: flex-end;   /* Align items to the right */
  margin-bottom: 30px;     /* Space between sections */
}

.bullet-column {
  display: flex;
  flex-direction: column;
  align-items: flex-end;   /* Align list items to the right */
}

.right-aligned {
  text-align: right;       /* Align text to the right */
  margin-bottom: 10px;     /* Space between heading and list */
}

/* Custom Style for List Items */
.bullet-column ul {
  list-style-type: none;
  padding-left: 0;
  text-align: right;       /* Align the list items to the right */
}

.bullet-column ul li {
  padding-left: 1.5rem;    /* Indentation for bullet points */
  position: relative;
}

.bullet-column ul li::before {
  content: '●';
  position: absolute;
  left: 0;
  color: #007bff;
  font-size: 1.2rem;
  top: 50%;
  transform: translateY(-50%);
}

/* Optional: For animation classes */
.animatable {
  transition: all 0.5s ease-in-out;
}

.animate-from-left {
  animation: slideInLeft 1s forwards;
}

.animate-from-right {
  animation: slideInRight 1s forwards;
}

/* Keyframes for animation */
@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

</style>

 