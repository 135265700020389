<!-- <div class="title-box uk-border-rounded p-4" style="background: 8bbdef; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
	<div class="header-section bg-dark text-white p-3 rounded-top text-center">
	  <h1 class="title-text font-weight-bold" style="background-color: #8bbdef;">Our Industries</h1> -->
	<!-- </div> -->
  <!-- </div>  -->
	  <!--------------------------------------------------TITLE END-------------------------------------------->
	<!-- Image Section -->
	<h1 class="title-text font-weight-bold text-center">
		<span class="highlight">O</span>UR <span class="highlight">I</span>NDUSTRIES
	  </h1>
	  
	 <div class="container-img">
		<div class="text-center my-4">
			<img style="width: 300px; height: 300px; " src="../../assets/img/home/it.jpg" alt="Company Image" class="responsive-img">
		</div>&nbsp;&nbsp;
		<div class="text-center my-4">
			<img style="width: 300px; height: 300px; " src="../../assets/img/home/construction.jpg" alt="Company Image" class="responsive-img">
		</div>&nbsp;&nbsp;
		<div class="text-center my-4">
			<img style="width: 300px; height: 300px; " src="../../assets/img/home/manufacture.jpg" alt="Company Image" class="responsive-img">
		</div>&nbsp;&nbsp;
		<div class="text-center my-4">
			<img style="width: 300px; height: 300px; " src="../../assets/img/home/oil.jpg" alt="Company Image" class="responsive-img">
		</div>

	 </div>
	 <br>
	 <div class="contact-info">
		<a href="tel:9894355288" style="border-radius: 20px;" class="contact-btn phone-btn">
		  <span class="fa fa-phone"></span> Call Now
		</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		<a href="mailto:Info@sbconsultancy.org.in,mailto:sbconsultancytnj&#64;gmail.com" style="border-radius: 20px;" class="contact-btn email-btn">
		  <span class="fa fa-envelope"></span> Email Us
		</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		<a href="https://wa.me/9894355288" target="_blank" style="border-radius: 20px;" class="contact-btn whatsapp-btn">
		  <span class="fa fa-whatsapp"></span> WhatsApp
		</a>
	  </div>
	  <br>
	 



  
  
  

  <div class="description-text text-justify mx-3">
	<!-- Responsive Two-column List -->
	<div class="industries-container">
		<h2 class="title">Diverse Industries at a Glance</h2>
		<div class="industries-grid">
		  <div class="industry-card animatable animate-from-right">
			<h3 style="color: white;">Advertisement</h3>
		  </div>
		  <div class="industry-card animatable animate-from-right">
			<h3 style="color: white;">Agriculture and Horticulture</h3>
		  </div>
		  <div class="industry-card animatable animate-from-right">
			<h3 style="color: white;">Air Conditioning and Refrigeration</h3>
		  </div>
		  <div class="industry-card animatable animate-from-right">
			<h3 style="color: white;">Aluminum</h3>
		  </div>
		  <div class="industry-card animatable animate-from-left">
			<h3 style="color: white;">Architects, Auditing, and Accounting</h3>
		  </div>
		  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		  &nbsp;&nbsp;<div class="industry-card animatable animate-from-left">
			<h3 style="color: white;" class="text-center">Automobile Engineering and Workshops</h3>
		  </div>
		  <div class="industry-card animatable animate-from-left">
			<h3 style="color: white;" class="text-center">Aviation, Airlines, and Airports Maintenance</h3>
		  </div>
		</div>
	  </div>
	  <br>
	  <div style="flex: 1; height: 2px; background: #ddd; margin: 0 10px; position: relative;">
		<i class="fa fa-rocket" style="position: absolute; top: -12px; left: 50%; transform: translateX(-50%); background: #fff; padding: 5px; color: #A35C7A; border-radius: 50%;"></i>
	  </div>
	<br>  
	  	  
	<div class="industry-section">
		<h2 class="section-title">Empowering Diverse Industries</h2>
		<div class="bullet-grid-container">
		  <div class="bullet-grid-item">
			<div class="icon-box">
			  <img src="../../assets/img/verticals/icon1sb.png" alt="Banking Icon" class="responsive-img" />
			</div>
			<h3>Banking & Financial Services</h3>
			<p>Tailored solutions for modern financial institutions.</p>
		  </div>
		  <div class="bullet-grid-item">
			<div class="icon-box">
			  <img src="../../assets/img/verticals/icon2sb.png" alt="Bakery Icon" class="responsive-img" />
			</div>
			<h3>Artisan Bakery Products</h3>
			<p>Quality ingredients for premium baked goods.</p>
		  </div> 
		  <div class="bullet-grid-item">
			<div class="icon-box">
			  <img src="../../assets/img/verticals/icon3sb.png" alt="Bottling Icon" class="responsive-img" />
			</div>
			<h3>Beverage Bottling Plants</h3>
			<p>State-of-the-art solutions for soft drink production.</p>
		  </div>
		  <div class="bullet-grid-item">
			<div class="icon-box">
			  <img src="../../assets/img/verticals/icon4sb.png" alt="Construction Icon" class="responsive-img" />
			</div>
			<h3>Construction & Maintenance</h3>
			<p>Comprehensive services for all building needs.</p>
		  </div>
		</div>
	  </div>

	  <br>
	  <div style="flex: 1; height: 2px; background: #ddd; margin: 0 10px; position: relative;">
		<i class="fa fa-rocket" style="position: absolute; top: -12px; left: 50%; transform: translateX(-50%); background: #fff; padding: 5px; color: #A35C7A; border-radius: 50%;"></i>
	  </div>
	<br> 
	<div class="section-title">
		<h2 >Innovative Engineering Solutions</h2>
	  </div>
	  
	  <div class="industry-sectors">
		<div class="sector-card">
			<h3 style="color: white;"> Engineering & Technology</h3>
			<ul>
				<li style="color: white;">Engines and Turbines</li>
				<li style="color: white;">Engineering & Project Management</li>
				<li style="color: white;">Electronics Engineering</li>
				<li style="color: white;">Electrical Contracting and Maintenance</li>
			</ul>
		</div>
	
		<div class="sector-card">
			<h3 style="color: white;">Energy & Power</h3>
			<ul>
				<li style="color: white;"> Electric Power Generation</li>
				<li style="color: white;">Engineering Procurement Installation <br> Construction (EPC)</li>
				<li style="color: white;">Exhibition and Trade Fair Organizers</li>
				<li style="color: white;">Educational Services</li>
			</ul>
		</div>
	</div>
	<br>
	<div style="flex: 1; height: 2px; background: #ddd; margin: 0 10px; position: relative;">
	  <i class="fa fa-rocket" style="position: absolute; top: -12px; left: 50%; transform: translateX(-50%); background: #fff; padding: 5px; color: #A35C7A; border-radius: 50%;"></i>
	</div>
  <br> 
  <!-- Explore Our Diverse Categories -->
  <div class="section-title">
	<h2 >Explore Our Diverse Categories</h2>
  </div>
  <div class="category-container">
    <div class="category-column">
        <ul>
            <li>Fashion</li>
            <li>Fabrication (Structure and Pressure Vessels)</li>
            <li>Fast-Moving Consumer Goods (FMCG)</li>
            <li>Food and Beverages</li>
        </ul>
    </div>
    <div class="category-column">
        <ul>
            <li>Fertilizer</li>
            <li>Fire-Fighting Services</li>
            <li>Food Processing</li>
            <li>Furniture and Interior Décor</li>
        </ul>
    </div>
</div>
	  <br>
	<div style="flex: 1; height: 2px; background: #ddd; margin: 0 10px; position: relative;">
	  <i class="fa fa-rocket" style="position: absolute; top: -12px; left: 50%; transform: translateX(-50%); background: #fff; padding: 5px; color: #A35C7A; border-radius: 50%;"></i>
	</div>
  <br> 

  <div class="services-container">
	<h2 class="services-title">Our Core Services</h2>
	<div class="services-columns">
	  <div class="services-list fade-in-right">
		<ul>
		  <li>Gold</li>
		  <li>Government Sectors</li>
		  <li>Gas Distribution Services</li>
		</ul>
	  </div>
	  <div class="services-list fade-in-left">
		<ul>
		  <li>General Trading and Marketing Services</li>
		  <li>Glass Manufacturing Services</li>  
		</ul>
	  </div>
	</div>
  </div>
	  <br>
	  <div style="flex: 1; height: 2px; background: #ddd; margin: 0 10px; position: relative;">
		<i class="fa fa-rocket" style="position: absolute; top: -12px; left: 50%; transform: translateX(-50%); background: #fff; padding: 5px; color: #A35C7A; border-radius: 50%;"></i>
	  </div>
	<br> 


	<div class="attractive">
		<h2 class="section-title text-center font-weight-bold" style="margin: 30px;">Sectors We Serve</h2>
		<div class="styled-attractive">
			<ul>
				<li>Health & Beauty</li>
				<li>Healthcare</li>
				<li>Hospitality</li>
			</ul>
		</div>
		<div class="styled-attractive-one">
			<ul>
				<li>Hospitals and Clinics</li>
				<li>Hotel Industry</li>    
				<li>Human Resource Solutions</li>    
			</ul>
		</div>
	</div>
	
	  <br>
	  <div style="flex: 1; height: 2px; background: #ddd; margin: 0 10px; position: relative;">
		<i class="fa fa-rocket" style="position: absolute; top: -12px; left: 50%; transform: translateX(-50%); background: #fff; padding: 5px; color: #A35C7A; border-radius: 50%;"></i>
	  </div>
	<br> 
	  <!-- <hr style="border: 1px solid #fb0c0c; margin: 30px 0; width: 100%;"> -->
	  <!-- <div class="bullet-container">
		<div class="bullet-column">
		  <ul class="animatable animate-from-right">
			<li>Market Research and Media Research Firms</li>
			<li>Media – Print, Publishing and E-Publishing</li>
			<li>Metalworking Machinery and Equipment</li>
		  </ul>
		</div>
		<div class="bullet-column">
		  <ul class="animatable animate-from-left">
			<li>Manufacturing Industry</li>
			<li>Mine and Refinery</li>	
			<li>Mills</li>	
		  </ul>
		</div>
	  </div> 
	  <hr style="border: 1px solid #fb0c0c; margin: 30px 0; width: 100%;">
	  <div class="bullet-container">
		<div class="bullet-column">
		  <ul class="animatable animate-from-right">
			<li>Packaging Material Manufacturers</li>
			<li>Paints & Emulsions</li>
			<li>Pharmaceuticals, Biopharmaceuticals and Bulk Drugs</li>
			<li>Printing Firms</li>
			<li>Petrochemical and Process</li>
		  </ul>
		</div>
		<div class="bullet-column">
		  <ul class="animatable animate-from-left">
			<li>Pesticides and Agro Chemicals</li>
			<li>Power and Utility</li>	
			<li>Poly-silicon</li>	
			<li>Ports</li>	
		  </ul>
		</div>
	  </div> 
	  <hr style="border: 1px solid #fb0c0c; margin: 30px 0; width: 100%;">
	  <div class="bullet-container">
		<div class="bullet-column">
		  <ul class="animatable animate-from-right">
			<li>Real Estate</li>
			<li>Refinery</li>
			<li>Railways and Metro Rail</li>
		  </ul>
		</div>
		<div class="bullet-column">
		  <ul class="animatable animate-from-left">
			<li>Road Transport and Surface Transport Services</li>
			<li>Rubbers, Plastics and Polymers</li>	
		  </ul>
		</div>
	  </div> 
  </div> -->
  <!-- <br>
  <div style="flex: 1; height: 2px; background: #ddd; margin: 0 10px; position: relative;">
	<i class="fa fa-cogs" style="position: absolute; top: -12px; left: 50%; transform: translateX(-50%); background: #fff; padding: 5px; color: #007bff; border-radius: 50%;"></i>
  </div>
  <br> -->
  <style>
	/* Animation Styles */
  .animatable {
	opacity: 0;
	transform: translateX(100px); /* Starts off to the right */
	transition: opacity 1s ease-out, transform 4s ease-out;
  }
  
  /* For elements that animate from the right */
  .animate-from-right {
	animation: slideInFromRight 4s forwards;
  }
  
  /* For elements that animate from the left */
  .animate-from-left {
	animation: slideInFromLeft 4s forwards;
  }
  
  /* Keyframes for sliding in from the right */
  @keyframes slideInFromRight {
	0% {
	  opacity: 0;
	  transform: translateX(100px);
	}
	100% {
	  opacity: 1;
	  transform: translateX(0);
	}
  }
  
  /* Keyframes for sliding in from the left */
  @keyframes slideInFromLeft {
	0% {
	  opacity: 0;
	  transform: translateX(-100px);
	}
	100% {
	  opacity: 1;
	  transform: translateX(0);
	}
  }
  
  /* Optional: Add a delay for the first element */
  .animatable.animate-from-right {
	animation-delay: 0.2s;
  }
  
  .animatable.animate-from-left {
	animation-delay: 0.4s;
  }
  
  /* Other styling for text */
  .description-text p {
	font-size: 18px; /* Adjust as per preference */
	color: #fefcfc;
	margin: 10px 0;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
	.description-text p {
	  font-size: 16px; /* Adjust for smaller screens */
	}
  }
	/* Global Styles */
	body {
	  font-family: Arial, sans-serif;
	  margin: 0;
	  padding: 0;
	  display: flex;
	  flex-direction: column;
	  align-items: center; /* Centers horizontally */
	  justify-content: center; /* Centers vertically */
	  min-height: 100vh; /* Ensures full-page height */
	  background-color: #f9f9f9; /* Optional background color */
	}
  
	p {
	  text-align: center;
	  font-size: 18px; /* Adjust as per preference */
	  color: #333; /* Optional text color */
	  margin: 10px 0;
	}
  
	.highlight-text {
	  font-weight: bold;
	  color: #A35C7A; 
	}
  
	.industries-container h2::first-letter{
		color: red;
		font-size: 150%;
	}
.industry-section h2::first-letter{
	color: red;
		font-size: 150%;
}
.section-title h2::first-letter{
	color: red;
	font-size: 150%;
}
.services-container h2::first-letter{
	color: red;
	font-size: 150%;
}
.attractive h2::first-letter{
	color: red;
	font-size: 150%;
}





  .responsive-img {
	width: 100%;              
	height: auto;               
	object-fit: cover;        
	max-height: 500px;          
  }
  .container-img{
	display:flex;
	width: 100%;
	margin: auto;
	align-items: center;
	justify-content: center;
	gap: 10px;

  }
  
  /* Mobile and tablet responsiveness */
  @media (max-width: 768px) {
	.responsive-img {
	  width: 100%;              
	  height: auto;             
	  object-fit: cover;        
	  max-height: 300px;       
	}
	.container-img{
		flex-direction: column;
	}
  }
  
  /* For larger screen sizes like tablets and desktops */
  @media (min-width: 769px) and (max-width: 1024px) {
	.responsive-img {
	  width: 80%;              
	  height: auto;             
	  object-fit: cover;       
	  max-height: 800px;       
	}
  }
  
  /* For desktop view */
  @media (min-width: 1025px) {
	.responsive-img {
	  width: 80%;              
	  height: auto;            
	  object-fit: cover;       
	  max-height: 700px;        
	}
  }
  
  
	/* Bullet Container */
	.bullet-container {
	  display: flex;
	  justify-content: space-between;
	  gap: 20px; 
	  max-width: 900px;
	  margin: 20px auto; 
	}
  
	/* Bullet Column Styling */
	.bullet-column {
	  flex: 1;
	  list-style: none; 
	  padding: 0;
	}
  
	.bullet-column ul {
	  padding-left: 50px; 
	  margin: 0;
	}
  
	.bullet-column li {
	  position: relative;
	  margin-bottom: 10px; 
	  font-size: 16px;
	  font-family: Arial, sans-serif;
	  color: #000;
	  line-height: 1.6;
	}
  
	/* Custom Bullet Design */
	.bullet-column li::before {
	  /* content: '●';  */
	  position: absolute;
	  left: -20px;
	  /* color: #007bff; */
	  font-size: 20px;
	}
  
	/* Media Queries for Mobile */
	@media (max-width: 768px) {
	  .bullet-container {
		flex-direction: column; 
		gap: 10px; 
		margin: 10px auto; 
	  }
  
	  .bullet-column {
		padding-left: 10px;
	  }
  
	  .bullet-column ul {
		padding-left: 30px; 
	  }
  
	  .bullet-column li {
		font-size: 14px; 
		margin-bottom: 8px; 
	  }
  
	  .bullet-column li::before {
		left: -15px; 
		font-size: 18px; 
	  }
	}
  
  </style>
  