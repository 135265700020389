<nav class="custom-navbar navbar navbar-expand-lg">
  <a class="navbar-brand" href="#" style="display: flex; align-items: center; text-decoration: none;">
    <img src="../../assets/img/icon/image (1).png" width="55px" alt="SB Consultancy" style="margin-right: 10px;" />
    <div>
      <h4 style="color: white; margin: 0; font-size: 20px; font-weight: bold;">SB CONSULTANCY</h4>
      <h5 class="subtext" style="color: white; margin: 5px 0 0; font-size: 10px;">International Manpower Consultant</h5>
    </div>
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
  </button>
  <div id="navbarNavDropdown" class="navbar-collapse collapse">
      <ul class="navbar-nav mr-auto">
          
      </ul>
      <ul class="navbar-nav">
      <li class="nav-item">  
        <a routerLink="/home-page" class="nav-link text-light active" routerLinkActive="active">  
         Home
        </a>  
      </li>  
      <li class="nav-item">  
        <a routerLink="/about-us" class="nav-link text-light" routerLinkActive="active">
         About Us 
        </a>  
      </li> 
      <li class="nav-item dropdown active">
          <div class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown">
            Services
          </div>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item" routerLink="/custom-software-development">Overseas Recruitment</a>
              <a class="dropdown-item" routerLink="/web-development">Domestic Recruitment</a>
              <a class="dropdown-item" routerLink="/mobile-development">Professional Staffing</a>
              <a class="dropdown-item" routerLink="/web-hosting">Payroll Management</a>
              <a class="dropdown-item" routerLink="/maintenance">Training</a>
              <a class="dropdown-item" routerLink="/Immigration">Immigration and Visa Assistance</a>
              <a class="dropdown-item" routerLink="/Ticketing">Ticketing</a>
          </div>
      </li>
      <li class="nav-item">  
          <a routerLink="/verticals" class="nav-link text-light" routerLinkActive="active">  
            Industries We Serve
          </a>  
      </li>  
      <li class="nav-item">  
          <a routerLink="/ourproducts" class="nav-link text-light" routerLinkActive="active">  
            Recruitment Process
          </a>  
      </li> 
      <li class="nav-item">  
        <a routerLink="/upload-cv" class="nav-link text-light" routerLinkActive="active">  
          Job Seeker
        </a>  
    </li>
    <li class="nav-item">  
      <a routerLink="/gallery" class="nav-link text-light" routerLinkActive="active">  
        Gallery
      </a>  
  </li>
      <li class="nav-item">  
          <a routerLink="/contact-us" class="nav-link text-light" routerLinkActive="active">  
            Contact
          </a>  
      </li>
    </ul>  
  </div>  
</nav>  
