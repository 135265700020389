<div class="hero-section">
  <br>
  <h1 class="text-center">IMMIGRATION & VISA ASSITANCE</h1>
  <img class="image-container" src="../../../assets/img/home/Immigrationandvisa.jpg" alt="">
</div>

<div class="container info-section">
  <div class="row">
      <div class="col-md-4">
          <div class="info-box">
              <h3>Expert Guidance</h3>
              <p>Our consultants guide you through every step of your immigration process.</p>
          </div>
      </div>
      <div class="col-md-4">
          <div class="info-box">
              <h3>Global Visas</h3>
              <p>We specialize in Canada, New Zealand, Germany, Denmark, and many other visa processes.</p>
          </div>
      </div>
      <div class="col-md-4">
          <div class="info-box">
              <h3>Career Counseling</h3>
              <p>We provide career and education counseling for young graduates.</p>
          </div>
      </div>
  </div>
</div>

<!-- Contact Section -->
<div class="contact-section">
  <h2>Ready to Apply?</h2>
  <p>Contact us today and get started on your visa application.</p>
  <a href="tel:+919876543210">Call Us: +91 98943 55288</a>
</div>
  
  <style>
    /* Main Container */
  .content-container {
    display: flex;
    justify-content: space-between; /* Space between left and right columns */
    gap: 20px; /* Adds spacing between columns */
    flex-wrap: wrap; /* Allows columns to wrap on smaller screens */
    padding: 20px;
  }

  .image-container{
    height: 500px;
    width: 1100px;
    margin-left: 130px;
  }
.hero-section h1::first-letter{
 color: red;
 font-size: 150%;
}




 
.info-section .row{
  box-shadow: 4px 4px 30px #FBF5E5;
}
        .breadcrumb a {
            text-decoration: none;
            color: #007bff;
            font-weight: bold;
        }
        .breadcrumb a:hover {
            text-decoration: underline;
        }
        .info-section {
            padding: 50px 20px;
            text-align: center;
        }
        .info-box {
            padding: 20px;
            background: #ffffff;
            border-radius: 10px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            margin-bottom: 20px;
        }
        .info-box h3 {
            font-size: 1.5rem;
            color: #333;
        }
        .info-box p {
            color: #000000;
        }
        .contact-section {
          background: linear-gradient(180deg, #C890A7, #A35C7A); 
    color: rgb(255, 255, 255);
    padding: 20px;
    text-align: center;
    border-radius: 50px;
}

.contact-section h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 15px;
}

.contact-section p {
  padding: 1cqw;
    font-size: 1.2rem;
    margin-bottom: 20px;
}

        .contact-section a {
            text-decoration: none;
            color: rgb(255, 255, 255);
            font-weight: bold;
            font-size: 1.2rem;
        }

.info-box h3::first-letter{
  color: red;
  font-size: 200%;
}








  /* ------------------------------------------------------------- */
  
  /* Left Column */
  .left-column {
    width: 48%; /* Takes half the page for larger screens */
    text-align: left;
  }
  
  /* Right Column */
  .right-column {
    width: 48%; /* Takes the other half of the page for larger screens */
    text-align: left;
  }
  
  /* Style for Headings */
  .right-aligned {
    color: #007bff;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  /* Style for Lists */
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  ul li {
    padding-left: 1.5rem;
    position: relative;
  }
  
  /* Add bullets using ::before */
  ul li::before {
    content: '●';
    position: absolute;
    left: 0;
    color: #007bff;
    font-size: 1.2rem;
    top: 50%;
    transform: translateY(-50%);
  }
  
  /* Add spacing between the first and second sections in the right column */
  .right-column p + ul {
    margin-top: 10px;
  }
  
  /* Responsive Design for Smaller Screens */
  @media (max-width: 768px) {
    .left-column,
    .right-column {
      width: 100%; /* Stacks both columns */
      margin-bottom: 20px; /* Adds spacing between stacked sections */
    }
    
    .content-container {
      gap: 0; /* Removes gap for stacked layout */
    }
  }
  .responsive-img {
  width: 80%;              
  height: auto;               
  object-fit: cover;        
  max-height: 800px;          
}

/* Mobile and tablet responsiveness */
@media (max-width: 768px) {
  .image-container {
    width: 80%;              
    height: auto;  
    align-items: center;
      text-align: center;
      justify-content: center;            
    object-fit: cover;        
    max-height: 800px;       
  }
}

/* For larger screen sizes like tablets and desktops */
@media (min-width: 769px) and (max-width: 1024px) {
  .image-container {
    width: 80%;              
    height: auto;
    align-items: center;
      text-align: center;
      justify-content: center;               
    object-fit: cover;       
    max-height: 800px;       
  }
}

/* For desktop view */
@media (min-width: 1025px) {
  .image-container {
    width: 80%;              
    height: auto; 
    align-items: center;
      text-align: center;
      justify-content: center;             
    object-fit: cover;       
    max-height: 700px;        
  }
}
    /* Animation Styles */
  .animatable {
    opacity: 0;
    transform: translateX(100px); /* Starts off to the right */
    transition: opacity 1s ease-out, transform 4s ease-out;
  }
  
  /* For elements that animate from the right */
  .animate-from-right {
    animation: slideInFromRight 4s forwards;
  }
  
  /* For elements that animate from the left */
  .animate-from-left {
    animation: slideInFromLeft 4s forwards;
  }
  
  /* Keyframes for sliding in from the right */
  @keyframes slideInFromRight {
    0% {
      opacity: 0;
      transform: translateX(100px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  /* Keyframes for sliding in from the left */
  @keyframes slideInFromLeft {
    0% {
      opacity: 0;
      transform: translateX(-100px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  /* Optional: Add a delay for the first element */
  .animatable.animate-from-right {
    animation-delay: 0.2s;
  }
  
  .animatable.animate-from-left {
    animation-delay: 0.4s;
  }
  
  /* Other styling for text */
  .description-text p {
    font-size: 18px; /* Adjust as per preference */
    color: #333;
    margin: 10px 0;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .description-text p {
      font-size: 16px; /* Adjust for smaller screens */
    }
  }
    /* Global Styles */
    body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center; /* Centers horizontally */
      justify-content: center; /* Centers vertically */
      min-height: 100vh; /* Ensures full-page height */
      background-color: #f9f9f9; /* Optional background color */
    }
  
    p {
      text-align: center;
      font-size: 18px; /* Adjust as per preference */
      color: #ffffff; /* Optional text color */
      margin: 10px 0;
    }
  
    .highlight-text {
      font-weight: bold;
      color: #007bff; 
    }
  
    /* Default Image Styling for all screen sizes */
  .responsive-img {
    width: 100%;              
    height: auto;               
    object-fit: cover;        
    max-height: 500px;          
  }
  
  /* Mobile and tablet responsiveness */
  @media (max-width: 768px) {
    .image-container {
      width: 80%;              
      height: auto;  
      margin-left: 35px;
      align-items: center;
      text-align: center;
      justify-content: center;             
      object-fit: cover;        
      max-height: 300px;       
    }
  }
  
  /* For larger screen sizes like tablets and desktops */
  @media (min-width: 769px) and (max-width: 1024px) {
    .image-container {
      width: 80%;              
      height: auto;
      margin-left: 0;
      align-items: center;
      text-align: center;
      justify-content: center;              
      object-fit: cover;       
      max-height: 400px;       
    }
  }
  
  /* For desktop view */
  @media (min-width: 1025px) {
    .image-container {
      width: 80%;              
      height: auto;
      align-items: center;
      text-align: center;
      justify-content: center;          
      object-fit: cover;       
      max-height: 500px;        
    }
  }
  
  
   /* Container to hold bullet points and headings */
  .bullet-container {
    display: flex;
    flex-direction: column;  /* Stack items vertically */
    align-items: flex-end;   /* Align items to the right */
    margin-bottom: 30px;     /* Space between sections */
  }
  
  .bullet-column {
    display: flex;
    flex-direction: column;
    align-items: flex-end;   /* Align list items to the right */
  }
  
  .right-aligned {
    text-align: right;       /* Align text to the right */
    margin-bottom: 10px;     /* Space between heading and list */
  }
  
  /* Custom Style for List Items */
  .bullet-column ul {
    list-style-type: none;
    padding-left: 0;
    text-align: right;       /* Align the list items to the right */
  }
  
  .bullet-column ul li {
    padding-left: 1.5rem;    /* Indentation for bullet points */
    position: relative;
  }
  
  .bullet-column ul li::before {
    content: '●';
    position: absolute;
    left: 0;
    color: #007bff;
    font-size: 1.2rem;
    top: 50%;
    transform: translateY(-50%);
  }
  
  /* Optional: For animation classes */
  .animatable {
    transition: all 0.5s ease-in-out;
  }
  
  .animate-from-left {
    animation: slideInLeft 1s forwards;
  }
  
  .animate-from-right {
    animation: slideInRight 1s forwards;
  }
  
  /* Keyframes for animation */
  @keyframes slideInLeft {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes slideInRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  </style>
   
