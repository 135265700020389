import { Component, OnInit } from '@angular/core';
import { GalleryPageServiceService } from '../gallery-page/gallery-page-service.service';


declare var bootbox: any;
declare var $: any;

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  constructor(private GalleryPageService: GalleryPageServiceService) {}

 slides = [
    { image: "../../assets/img/home/SB/1.jpg", caption: 'Quality organizations,great people.' },
    { image: "../../assets/img/home/SB/2.jpg", caption: 'Recruiting top IT talent.' },
    { image: "../../assets/img/home/SB/3.jpg", caption: 'Professional Staffing.' },
    { image: "../../assets/img/home/SB/4.jpg", caption: 'We offer a wide range of staffing.' },
    { image: "../../assets/img/home/SB/5.jpg", caption: 'Quality Manpower anytime, anywhere.' },
    
  ];
  
  currentIndex = 0;
  images: any[] = [];

  ngOnInit() {
    this.startSlideshow();
    this.loadGallery();
  }

  startSlideshow() {
    setInterval(() => {
      this.currentIndex = (this.currentIndex + 1) % this.slides.length;
    }, 5000); // Change slide every 2 seconds
  }

  loadGallery(): void {
    this.GalleryPageService.getJobImage().subscribe(
      (data) => {
        this.images = data;
      },
      (error) => {
        console.error('Error loading gallery', error);
      }
    );
  }
}
