<div class="container">
  <div class="title-box">
      <div class="header-section text-black p-3 text-center">
          <h1 class="title-text font-weight-bold">
              <span class="highlight">R</span>ecruitment 
              <span class="highlight">P</span>rocess
          </h1>
      </div>
  </div>
</div>
  <!-- <div class="breadcrumb-section mt-3 text-right">
    <h5 class="text-muted">
        <span class="sub-menu" routerLink="/home-page">Home </span> -->
        <!-- <span class="sub-menu sub-menu-text font-weight-bold">Custom Software Development</span>&nbsp;/&nbsp; -->
        <!-- <span class="sub-menu" routerLink="/Immigration">Immigration and Visa Assistance</span> -->
      <!-- </h5>
    </div> -->
<div class="description-text text-justify mx-3">
  <p  class="animatable animate-from-right">
    <span> Our capabilities are completely scalable and our processes provide 
      industry-leading results,regardless of the size of your company or the project in question.</span><br>
    <span>Some of theareas where we assist our clients are:</span>
  </p>
  <br>
  <div class="contact-info">
    <a href="tel:9894355288" class="contact-btn phone-btn">
      <span class="fa fa-phone"></span> Call Now
    </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <a href="mailto:Info@sbconsultancy.org.in,mailto:sbconsultancytnj&#64;gmail.com" class="contact-btn email-btn">
      <span class="fa fa-envelope"></span> Email Us
    </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <a href="https://wa.me/9894355288" target="_blank" class="contact-btn whatsapp-btn">
      <span class="fa fa-whatsapp"></span> WhatsApp
    </a>
  </div>
  <br>
  <p class="responsive-letter" style="font-size: 35px; font-weight: bold; color: #000000;">
    Selection
  </p>
  <div class="text-center my-4">
  <img src="../../assets/img/contact/Selection.jpg" alt="Company Image" class="responsive-img">
</div>
    <p>
      We prefer selection process either by practical test or oral test, which conduct under the direction of the authorized representative. 
      We arrange his/her accommodation, transport and all other support required for the purpose. 
      In case we are entrusted with the selection & testing of the worker, we normally do it with the help of a team of knowledgeable 
      experts for technical & professional categories under the direct supervision of our professional executives.
    </p>
  <p class="responsive-letter" style="font-size: 35px; font-weight: bold; color: #000000;">
    Medical Checkup
  </p>

  <div class="text-center my-4">
    <img src="../../assets/img/contact/Medicalcheckup.jpg" alt="Company Image" class="responsive-img">
  </div>
  <p class="animatable animate-from-left">
    After the selection of the candidates, we send them for the medical checkup in the authorized medical center of the respective Embassies of the nation of employment.
  </p>
  <p class="responsive-letter" style="font-size: 35px; font-weight: bold; color: #000000;">
    Application for VISA
  </p>
  <div class="text-center my-4">
    <img src="../../assets/img/contact/VisaForm.jpg" alt="Company Image" class="responsive-img">
  </div>
  <p class="animatable animate-from-left">
    After the final selection of the selected candidate, we apply for the visa as per the authorized term of the embassy of countries.
  </p>
  <p class="responsive-letter" style="font-size: 35px; font-weight: bold; color: #000000;">
    Ticketing and Emigration
  </p>
  <div class="text-center my-4">
    <img src="../../assets/img/contact/Ticketing and Emigration.jpg" alt="Company Image" class="responsive-img">
  </div>
  <p class="animatable animate-from-left">
    To make possible travelling as per plan, the personnel may send PTA or revoke obligatory transportation expenditure in compliance of SB Consultants. Our firm attains departure authorization from the respective Govt. Dept. for the applicants.
  </p>
  <p class="responsive-letter" style="font-size: 35px; font-weight: bold; color: #000000;">
    Orientation before Departure
  </p>
  <div class="text-center my-4">
    <img src="../../assets/img/contact/Orientation before Departure.jpg" alt="Company Image" class="responsive-img">
  </div>
  <p class="animatable animate-from-left">
    Our organization offers vitaldirection to workers before travelling overseas. 
    The personnel is well-versedabout their duties and responsibilities while the overseas and firsthandacquaintance 
    of work environment there and most important features of labor laws of the country of employment.
  </p>
<br>
<br>
<div style="flex: 1; height: 2px; background: #ddd; margin: 0 10px; position: relative;">
  <i class="fa fa-cogs" style="position: absolute; top: -12px; left: 50%; transform: translateX(-50%); background: #fff; padding: 5px; color: #A35C7A; border-radius: 50%;"></i>
</div>
<br>
<br>


  <main class="container">
    <div class="title-box uk-border-rounded p-4" style="box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); border-radius: 12px; overflow: hidden;">
      <div class="header-section text-white p-3 text-center" style="background-color: #fbfbfb; border-radius: 12px 12px 0 0;">
        <h1 class="title-text font-weight-bold" style="color: rgb(0, 0, 0); margin: 0;">Consultancy Process</h1>
      </div>
      <div class="content p-4">
        <h2 class="sub-heading text-center font-weight-bold" style="color: #000000; text-decoration: none;">Understanding Client Needs</h2>
        <p>We begin by thoroughly understanding the requirements of our clients,
           including the job role, required skills, and organizational culture, to ensure the perfect fit.</p>
          
  
        <h2 class="sub-heading text-center font-weight-bold" style="color: #000000; text-decoration: none;">Sourcing Candidates</h2>
        <p>Our extensive network and advanced tools allow us to source top talent from various platforms, 
          databases, and referrals, ensuring we identify the best candidates for the role.</p>
  
        <h2 class="sub-heading text-center font-weight-bold" style="color: #000000; text-decoration: none;">Screening and Shortlisting</h2>
        <p>We conduct initial screenings to evaluate candidates based on qualifications, experience, 
          and alignment with client expectations. A shortlist of the most suitable candidates is then prepared.</p>
  
        <h2 class="sub-heading text-center font-weight-bold" style="color: #000000; text-decoration: none;">Interview Coordination</h2>
        <p>We manage the entire interview process, including scheduling, follow-ups, and providing feedback, 
          ensuring a smooth experience for both clients and candidates.</p>
  
        <h2 class="sub-heading text-center font-weight-bold" style="color: #000000; text-decoration: none;">Final Selection and Onboarding</h2>
        <p>We assist in the final stages of candidate selection, offer negotiation, and onboarding, 
          ensuring a seamless transition into the client’s organization.</p>
  
        <h2 class="sub-heading text-center font-weight-bold" style="color: #000000; text-decoration: none;">Post-Recruitment Support</h2>
        <p>Our support doesn’t end with recruitment. We provide post-recruitment services to ensure the long-term success of placements.</p>
        
        <!-- Add more sections or visual elements like images or icons if needed -->
      </div>
    </div>
  </main>
  <br>
<br>
<div style="flex: 1; height: 2px; background: #ddd; margin: 0 10px; position: relative;">
<i class="fa fa-cogs" style="position: absolute; top: -12px; left: 50%; transform: translateX(-50%); background: #fff; padding: 5px; color: #A35C7A; border-radius: 50%;"></i>
</div>
<br>
<br>
  

  

<style>

.header-section {
  font-size: 1.5rem;
  font-weight: bold;
}

.sub-heading {
  font-size: 1.25rem;
  margin-top: 1rem;
  text-decoration: underline;
}

.highlight {
            color: red;
            font-weight: bold;
        }





.animatable {
  opacity: 0;
  transform: translateX(100px); /* Starts off to the right */
  transition: opacity 1s ease-out, transform 4s ease-out;
}

/* For elements that animate from the right */
.animate-from-right {
  animation: slideInFromRight 4s forwards;
}

/* For elements that animate from the left */
.animate-from-left {
  animation: slideInFromLeft 4s forwards;
}

/* Keyframes for sliding in from the right */
@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Keyframes for sliding in from the left */
@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Optional: Add a delay for the first element */
.animatable.animate-from-right {
  animation-delay: 0.2s;
}

.animatable.animate-from-left {
  animation-delay: 0.4s;
}

/* Other styling for text */
.description-text p {
  font-size: 18px; /* Adjust as per preference */
  color: #333;
  margin: 10px 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .description-text p {
    font-size: 16px; /* Adjust for smaller screens */
  }
}
  /* Global Styles */
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers horizontally */
    justify-content: center; /* Centers vertically */
    min-height: 100vh; /* Ensures full-page height */
    background-color: #f9f9f9; /* Optional background color */
  }

  p {
    text-align: center;
    font-size: 18px; /* Adjust as per preference */
    color: #333; /* Optional text color */
    margin: 10px 0;
  }

  .highlight-text {
    font-weight: bold;
    color: #007bff; 
  }

  /* Default Image Styling for all screen sizes */
.responsive-img {
  width: 100%;              
  height: auto;               
  object-fit: cover;        
  max-height: 800px;          
}

/* Mobile and tablet responsiveness */
@media (max-width: 768px) {
  .responsive-img {
    width: 100%;              
    height: auto;             
    object-fit: cover;        
    max-height: 300px;       
  }
}

/* For larger screen sizes like tablets and desktops */
@media (min-width: 769px) and (max-width: 1024px) {
  .responsive-img {
    width: 80%;              
    height: auto;             
    object-fit: cover;       
    max-height: 800px;       
  }
}

/* For desktop view */
@media (min-width: 1025px) {
  .responsive-img {
    width: 80%;              
    height: auto;            
    object-fit: cover;       
    max-height: 700px;        
  }
}


  /* Bullet Container */
  .bullet-container {
    display: flex;
    justify-content: space-between;
    gap: 20px; 
    max-width: 900px;
    margin: 20px auto; 
  }

  /* Bullet Column Styling */
  .bullet-column {
    flex: 1;
    list-style: none; 
    padding: 0;
  }

  .bullet-column ul {
    padding-left: 50px; 
    margin: 0;
  }

  .bullet-column li {
    position: relative;
    margin-bottom: 10px; 
    font-size: 16px;
    font-family: Arial, sans-serif;
    color: #000;
    line-height: 1.6;
  }

  /* Custom Bullet Design */
  .bullet-column li::before {
    /* content: '●';  */
    position: absolute;
    left: -20px;
    /* color: #007bff; */
    font-size: 20px;
  }

  /* Media Queries for Mobile */
  @media (max-width: 768px) {
    .bullet-container {
      flex-direction: column; 
      gap: 10px; 
      margin: 10px auto; 
    }

    .bullet-column {
      padding-left: 10px;
    }

    .bullet-column ul {
      padding-left: 30px; 
    }

    .bullet-column li {
      font-size: 14px; 
      margin-bottom: 8px; 
    }

    .bullet-column li::before {
      left: -15px; 
      font-size: 18px; 
    }
  }

</style>