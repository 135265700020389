<div class="">
  <!------------------------------------------ HEADER IMAGE --------------------------------------->
  <!------------------------------------------TITLE----------------------------------------------->
  <div class="title-box uk-border-rounded p-1  ">
    <div class="head-card p-1 inner-title-box">
      <h1 class="title-text text-center font-weight-bold">Gallery</h1>
     
    </div>
  </div>
  <!--------------------------------------------------TITLE END-------------------------------------------->
  <!------------------------------------------ END ------------------------------------------------->

  <!------------------------------------------ WEBSITES---------------------------------------------->
  <div class="bg-light">
    <div class="container-fluid">
      <!-- Page Title -->
      <h4
        class="font-weight-bolder align-middle text-center text-uppercase d-block d-sm-none"
      >
        Gallery
      </h4>

      <div class="gallery-container">
        <div *ngIf="images.length === 0">No images available.</div>
        <div *ngFor="let image of images" class="image-item">
          <div class="image-wrapper">
            <img [src]="image.filePath" [alt]="image.name" />
          </div>
          <!-- <p>{{ image.name }}</p> -->
        </div>
      </div>
      
    </div>
  </div>
</div>
<!--------------------------------------- MOBILE VIEW ------------------------------------------------->

<!-- <div class="d-block d-sm-none">
  <!------------------------------------------ HEADER IMAGE --------------------------------------->
  <!------------------------------------------TITLE-----------------------------------------------

  <div class="bg-light">
    <div class="container-fluid">
       Page Title 
      <h4
        class="font-weight-bolder align-middle text-center text-uppercase d-block d-sm-none"
      >
        Gallery
      </h4>


    </div>
  </div>
</div> -->
