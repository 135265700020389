
    <!------------------------------------------TITLE----------------------------------------------->
    <!DOCTYPE html>
<html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <div class="title-box uk-border-rounded p-4">
        <div class="header-section bg-dark text-white p-3 rounded-top text-center">
          <h1 class="title-text font-weight-bold">JOB SEEKER FORM</h1>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css" rel="stylesheet">
        </div>
      </div>
  <!--------------------------------------------------TITLE END-------------------------------------------->


  
  <style>
    body {
      padding: 20px;
    }
    .form-container {
      max-width: 1200px;
      margin: 0 auto;
    }
    .form-label {
      font-size: 0.9rem;
      font-weight: bold;
    }
    .submit-btn {
      margin-top: 20px;
      text-align: center;
    }
    .custom-placeholder {
  position: absolute;
  top: 35px; /* Adjust based on your input height */
  left: 10px;
  color: #6c757d; /* Matches the default placeholder color */
  pointer-events: none; /* Prevent interaction with the placeholder */
  transition: 0.2s;
}

input:focus + .custom-placeholder,
input:not(:placeholder-shown) + .custom-placeholder {
  opacity: 0; /* Hide the custom placeholder when the user interacts with the input */
}
  </style>
</head>
<body>
  <div class="form-container">
    <h3 class="text-center mb-4">Job Seeker Form</h3>
    <form  autocomplete="off">
        <div class="row g-3">
            <!-- First Row -->
            <div class="col-md-4 col-sm-6">
              <label for="firstName" class="form-label" style="color: black;">First Name</label>
                <input
                    type="text"
                    id="firstName"
                    class="form-control"
                    [(ngModel)]="jobSeekerForm.firstName"
                    name="firstName"
                    placeholder="Enter First Name (required)"
                    required
                />
            </div>
            <div class="col-md-4 col-sm-6">
              <label for="middleName" class="form-label" style="color: black;">Middle Name</label>
                <input
                    type="text"
                    id="middleName"
                    class="form-control"
                    [(ngModel)]="jobSeekerForm.middleName"
                    name="middleName"
                    placeholder="Enter middleName Name"
                />
            </div>
            <div class="col-md-4 col-sm-12">
              <label for="lastName" class="form-label" style="color: black;">Last Name</label>
                <input
                type="text"
                id="lastName"
                class="form-control"
                [(ngModel)]="jobSeekerForm.lastName"
                name="lastName"
                placeholder="Enter lastName Name (required)"
                required
            />
            </div>
        
            <!-- Second Row -->
            <div class="col-md-4 col-sm-6">
              <label for="gender" class="form-label" style="color: black;">Choose Gender</label>
              <select id="gender" class="form-select" [(ngModel)]="jobSeekerForm.gender" name="gender" required>
                <option selected disabled>--Choose Gender--</option>
                <option value="M">Male</option>
                <option value="F">Female</option>
                <option value="O">Other</option>
              </select>
            </div>            
            <div class="col-md-4 col-sm-6">
              <label for="mailId" class="form-label" style="color: black;">Enter the Mail</label>
              <input
              type="text"
              id="mailId"
              class="form-control"
              [(ngModel)]="jobSeekerForm.mailId"
              name="mailId"
              placeholder="Enter mail Here (required)"
              required
          />
            </div>
            <div class="col-md-4 col-sm-12" style="position: relative;">
              <label for="dob" class="form-label" style="color: black;">Select Date of Birth</label>
              <input type="date" id="dob" class="form-control" [(ngModel)]="jobSeekerForm.dob" name="dob" required>
              <span class="custom-placeholder">Select Date of Birth (required)</span>
            </div>
                  
            <!-- Third Row -->
            <div class="col-md-4 col-sm-6">
              <label for="mobileNo" class="form-label" style="color: black;">MobileNo</label>
                <input
                type="text"
                id="mobileNo"
                class="form-control"
                [(ngModel)]="jobSeekerForm.mobileNo"
                name="mobileNo"
                placeholder="Enter mobileNo Here (required)"
                required
            />
            </div>
            <div class="col-md-4 col-sm-6">
              <label for="qualification" class="form-label" style="color: black;">Qualification</label>
                <input
                type="text"
                id="qualification"
                class="form-control"
                [(ngModel)]="jobSeekerForm.qualification"
                name="qualification"
                placeholder="Enter qualification Here (required)"
                required
            />
            </div>
            <div class="col-md-4 col-sm-12">
              <label for="designation" class="form-label" style="color: black;">Designation</label>
                <input
                type="text"
                id="designation"
                class="form-control"
                [(ngModel)]="jobSeekerForm.designation"
                name="designation"
                placeholder="Enter designation Here (required)"
                required
            />
            </div>

            <!-- Fourth Row -->
            <div class="col-md-4 col-sm-6">
              <label for="experience" class="form-label" style="color: black;">Choose Years of Experience</label>
              <select id="experience" class="form-select" [(ngModel)]="jobSeekerForm.experience" name="experience">
                <option selected disabled>--Choose Years of Experience--</option>
                <option value="1-3">1-3 years</option>
                <option value="3-5">3-5 years</option>
                <option value="5+">5+ years</option>
              </select>
            </div>
            
            <div class="col-md-4 col-sm-6">
              <label for="passPortNo" class="form-label" style="color: black;">PassPort No</label>
                <input
                type="text"
                id="passPortNo"
                class="form-control"
                [(ngModel)]="jobSeekerForm.passPortNo"
                name="passPortNo"
                placeholder="Enter passPortNo Here (required)"
                required
            />
            </div>
            <div class="col-md-4 col-sm-12">
              <label for="cSalary" class="form-label" style="color: black;">Current Salary</label>
                <input
                type="text"
                id="cSalary"
                class="form-control"
                [(ngModel)]="jobSeekerForm.cSalary"
                name="cSalary"
                placeholder="Enter Salary Here (required)"
                required
            />
            </div>

            <!-- Fifth Row -->
            <div class="row g-3">
                <div class="col-md-4 col-sm-6">
                  <label for="address" class="form-label" style="color: black;">Address</label>
                    <input
                    type="text"
                    id="address"
                    class="form-control"
                    [(ngModel)]="jobSeekerForm.address"
                    name="address"
                    placeholder="Enter address Here (required)"
                    required
                />
                </div>
                <div class="col-md-4 col-sm-6">
                  <label for="city" class="form-label" style="color: black;">City</label>
                    <input
                    type="text"
                    id="city"
                    class="form-control"
                    [(ngModel)]="jobSeekerForm.city"
                    name="city"
                    placeholder="Enter city Here (required)"
                    required
                />
                </div>
                <div class="col-md-4 col-sm-12">
                    <div class="row g-2">
                        <!-- Postal Code -->
                        <div class="col-6">
                          <label for="postalCode" class="form-label" style="color: black;">Postal Code</label>
                            <input
                    type="text"
                    id="postalCode"
                    class="form-control"
                    [(ngModel)]="jobSeekerForm.postalCode"
                    name="postalCode"
                    placeholder="Enter postalCode Here (required)"
                    required
                />
                        </div>
                        <!-- Country Dropdown -->
                        <div class="col-6">
                          <label for="country" class="form-label" style="color: black;">Choose Country</label>
                          <select id="country" class="form-select" [(ngModel)]="jobSeekerForm.country" name="country" required>
                            <option value="" disabled selected>-- Choose Country --</option>
                            <option value="IND">India</option>
                            <option value="USA">USA</option>
                            <option value="UK">UK</option>
                              <option value="Australia">Australia</option>
                              <option value="Canada">Canada</option>
                              <option value="Germany">Germany</option>
                              <option value="France">France</option>
                              <option value="Italy">Italy</option>
                              <option value="Spain">Spain</option>
                              <option value="Brazil">Brazil</option>
                              <option value="Japan">Japan</option>
                              <option value="South Korea">South Korea</option>
                              <option value="China">China</option>
                              <option value="Mexico">Mexico</option>
                              <option value="Russia">Russia</option>
                              <option value="South Africa">South Africa</option>
                              <option value="Argentina">Argentina</option>
                              <option value="New Zealand">New Zealand</option>
                              <option value="Saudi Arabia">Saudi Arabia</option>
                              <option value="UAE">UAE</option>
                              <option value="Egypt">Egypt</option>
                              <option value="Turkey">Turkey</option>
                              <option value="Sweden">Sweden</option>
                              <option value="Netherlands">Netherlands</option>
                              <option value="Belgium">Belgium</option>
                          </select>                          
                        </div>
                    </div>
                </div>
            </div>

            <!-- Submit Button -->
            <div class="submit-btn col-12">
                <button type="submit"  style="color: #ffffff; background-color: #A35C7A; border: none;" (click)="submit()">Submit</button>
            </div>
        </div>
    </form>
</div>

  <!-- Bootstrap JS -->
  <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/js/bootstrap.bundle.min.js"></script>
</body>
</html>