import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { VerticalsComponent } from './verticals/verticals.component';
import { ClientsComponent } from './clients/clients.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CustomSoftwareDevelopmentComponent } from './services/custom-software-development/custom-software-development.component';
import { WebDevelopmentComponent } from './services/web-development/web-development.component';
import { MobileDevelopmentComponent } from './services/mobile-development/mobile-development.component';
import { WebHostingComponent } from './services/web-hosting/web-hosting.component';
import { MaintenanceComponent } from './services/maintenance/maintenance.component';
import { UploadCvComponent } from './careers/upload-cv/upload-cv.component';
import { AbstractComponent } from './clients/abstract/abstract.component';
import { LoginComponent } from './login/login.component';
import { OurProductsComponent } from './our-products/our-products.component';
import { ProductDetailsComponent } from './our-products/product-details/product-details.component';
import { ImmigrationComponent } from './services/immigration/immigration.component';
import { TicketingComponent } from './services/ticketing/ticketing.component';
import { GalleryPageComponent } from './gallery-page/gallery-page.component';


const routes: Routes = [
  { path:'',component: HomePageComponent},
  { path:'home-page',component:HomePageComponent},
  { path:'about-us',component:AboutUsComponent},
  { path:'custom-software-development',component:CustomSoftwareDevelopmentComponent},
  { path:'web-development',component:WebDevelopmentComponent},
  { path:'mobile-development',component:MobileDevelopmentComponent},
  { path:'web-hosting',component:WebHostingComponent},
  { path:'maintenance',component:MaintenanceComponent},
  { path:'Immigration',component:ImmigrationComponent},
  { path:'Ticketing',component:TicketingComponent},
  { path:'verticals',component:VerticalsComponent},
  { path:'clients',component:ClientsComponent},
  { path:'testimonials',component:TestimonialsComponent},
  { path:'upload-cv',component: UploadCvComponent},
  { path:'contact-us',component: ContactUsComponent},
  { path:'child-abstract/:data',component: AbstractComponent},
  { path: 'login' ,component: LoginComponent},
  { path:'ourproducts', component: OurProductsComponent},
  { path:'child-productdetails/:data',component: ProductDetailsComponent},
  { path:'gallery',component: GalleryPageComponent},



];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
